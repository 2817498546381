<script lang="ts" setup>
import { GButtonV2, GBaseIcon } from '@gem/uikit';
import { getLabel } from '../common';
import type { CompareType } from '../constant';
import { COMPARE_OPTIONS } from '../constant';
import { computed } from 'vue';
import type { IconName } from '@gem/icons';

const props = defineProps<{
  quantityType?: CompareType;
}>();

const emit = defineEmits<{
  (event: 'onChange', value: CompareType, icon: string): void;
}>();

const icon = computed<IconName | undefined>(() => {
  if (!props.quantityType) return 'equal-or-bigger';
  const temp = COMPARE_OPTIONS.find((option) => option.id === props.quantityType)?.icon as IconName | undefined;
  return temp;
});
</script>

<template>
  <g-popover
    :overlay="false"
    :has-arrow="false"
    :closeable="true"
    :stop-propagation="true"
    cls="bg-dark-400 absolute left-32 w-[180px] rounded-xl p-8 compare-select">
    <template #default="{ open }">
      <GButtonV2
        ref="triggerButton"
        :only-icon="icon"
        :type="open ? 'primaryGhost' : 'tertiary'"
        icon-size="20"
        size="medium"
        :active="open" />
    </template>
    <template #content="{ close }">
      <div
        v-for="option in COMPARE_OPTIONS"
        :key="option.id"
        class="hover:bg-dark-250 flex h-36 cursor-pointer items-center gap-12 rounded-xl px-8"
        @click="
          () => {
            emit('onChange', option.id, option.icon);
            close();
          }
        ">
        <div class="flex flex-1 items-center gap-12">
          <GBaseIcon
            name="status-check"
            width="16"
            height="16"
            class="invisible"
            :class="{
              '!visible': quantityType === option.id,
            }" />
          <p class="text-text-dark-500 text-12 leading-5">{{ getLabel(option.id) }}</p>
        </div>
        <GBaseIcon :name="option.icon" width="16" height="16" />
      </div>
    </template>
  </g-popover>
</template>
