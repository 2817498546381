<script lang="ts" setup>
import { GIcon } from '@gem/icons';
import { GTooltip } from '@gem/uikit-v2';
import type { ColumnItemsType } from '../types';

const props = withDefaults(defineProps<ColumnItemsType>(), {
  startCustomNumber: 6,
});

const emit = defineEmits<{
  (e: 'control-change', value: string): void;
}>();

const handleChangeLayout = (value: string) => {
  emit('control-change', value);
};

const isCustom = (cols: number) => {
  return cols >= props.startCustomNumber;
};
</script>

<template>
  <div class="grid w-full grid-cols-3 gap-16">
    <div class="w-full" v-for="cols in startCustomNumber" :key="cols">
      <GTooltip
        :disabled="!disableItem || disableItem?.id !== cols"
        placement="top"
        strategy="fixed"
        display-type="block">
        <div
          class="flex h-36 cursor-pointer items-center justify-center rounded-[6px] px-4"
          :class="{
            'bg-underlay-dark-blue': cols.toString() === value?.toString(),
            'cursor-no-drop !bg-transparent': disableItem?.id === cols,
          }"
          @click="
            () => {
              if (disableItem?.id === cols) return;
              handleChangeLayout(isCustom(cols) ? 'custom' : cols.toString());
            }
          ">
          <div
            class="group/box grid h-28 w-full gap-[2px]"
            :style="{ gridTemplateColumns: `repeat(${isCustom(cols) ? 1 : cols}, 1fr)` }">
            <template v-if="!isCustom(cols)">
              <span
                v-for="(_, index) in cols"
                :key="index"
                class="rounded-[3px]"
                :class="{
                  'bg-primary-300': cols.toString() === value?.toString(),
                  'bg-dark-200 group-hover/box:bg-dark-150': cols.toString() !== value?.toString(),
                  '!bg-dark-400': disableItem?.id === cols,
                }" />
            </template>
            <div
              v-else
              class="flex items-center justify-center rounded-[3px]"
              :class="{
                'bg-primary-300 text-primary-100': Number(value ?? 1) >= startCustomNumber,
                'bg-dark-200 group-hover/box:bg-dark-150 text-text-dark-300': Number(value ?? 1) < startCustomNumber,
              }">
              <GIcon name="polaris-settings-filled" :size="16" />
            </div>
          </div>
        </div>
        <template #content>
          <span v-html="disableItem?.message" />
        </template>
      </GTooltip>
    </div>
  </div>
</template>
