import type { GalleryDeletedImageListProps, GalleryImageItem } from '../../types';

import { ref, computed } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
import { convertImageFile, getImageUrlPreview, handleFormatFileName } from '../../helpers';
import { useImageSelectContext } from '../useImageSelectContext';

export const useGalleryDeletedImageList = (props: GalleryDeletedImageListProps) => {
  const { actions, state } = useImageSelectContext();

  const listContainer = ref<HTMLElement | null>(null);

  useInfiniteScroll(
    listContainer,
    () => {
      if (!props?.galleryData?.viewMoreDeleted) return;
      actions.handleShowMoreImage('deleted');
    },
    { distance: 100 },
  );

  const imageList = computed<GalleryImageItem[]>(() => {
    return state.deletedImageList?.value
      ?.map((item) => convertImageFile(item))
      .map((image) => ({
        ...image,
        name: handleFormatFileName(image),
        id: '',
        isLoading: !isImageLoaded(image.id ?? ''),
        previewUrl: getImageUrlPreview(image),
      }));
  });

  const handleRestoreImage = (id?: string) => {
    if (!id) return;
    actions.handleRestoreImage(id);
  };

  const handleForceDeleteImage = (id: string) => {
    actions.handleForceDeleteImage(id);
  };

  const handleSetImageLoaded = (id: string) => {
    props.loadedList?.push(id);
  };

  const isImageLoaded = (id: string) => {
    return props.loadedList.includes(id);
  };

  return {
    imageList,
    listContainer,
    isImageLoaded,
    handleRestoreImage,
    handleSetImageLoaded,
    handleForceDeleteImage,
  };
};
