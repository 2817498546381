import type { FontFamilyType } from '@gem/control';

const ELEMENT_CLASS = 'google-fonts-element';
const BUNNY_FONTS_BASE_URL = 'https://fonts.bunny.net/css';
const GOOGLE_FONTS_BASE_URL = 'https://fonts.googleapis.com/css2';

export const useFontLoader = () => {
  const handleLoadSourceFont = (fontFamily: string, type: FontFamilyType) => {
    const encodedFont = encodeURI(fontFamily);
    const baseUrl = type === 'bunny' ? BUNNY_FONTS_BASE_URL : GOOGLE_FONTS_BASE_URL;
    const fontUrl = `${baseUrl}?family=${fontFamily}${type === 'bunny' ? '&display=fallback' : ''}`;

    const existingLink = document.querySelector(`.${ELEMENT_CLASS}[data-font="${encodedFont}"]`) as HTMLLinkElement;

    if (existingLink) {
      if (existingLink.href !== fontUrl) {
        existingLink.href = fontUrl;
      }
      return;
    }

    const link = document.createElement('link');

    link.href = fontUrl;
    link.rel = 'stylesheet';
    link.className = ELEMENT_CLASS;
    link.dataset.font = encodedFont;

    document.head.appendChild(link);
  };

  return { handleLoadSourceFont };
};
