import type { Maybe } from '@gem/common';
import { cloneDeepObject, debounce } from '@gem/common';
import type { ComputedRef } from 'vue';
import { nextTick } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import type { List, PickLinkProps } from '../types';
import { useDomainAutoComplete } from './useDomainAutoComplete';
import { useParseLinkToType } from './useParseLinkToType';
import type { IconName } from '@gem/icons';
import { useSearchLink } from './useSearchLink';
import { useInfiniteScrollHandler } from './useInfiniteScrollHandler';

export const usePickLink = (props: PickLinkProps, emit: any) => {
  const defaultType = 'open-page';
  const typeLink = ref<string>(defaultType);
  const val = computed(() => props.value);
  const isEnableMoreSetting = computed(() => isTypeOpenPage.value && val.value?.link !== '');
  const dataSources = computed(() => props.dataSources);
  const currentTab = computed(() => props.currentTab ?? 'pages');
  const isInstant = computed(() => props.isInstant);
  const shopDomain = computed(() => props.shopDomain);
  const apiLoading = computed(() => props.apiLoading);
  const hiddenOptions = computed(() => props.hiddenOptions);
  const list = ref(dataSources.value[currentTab.value]);
  const originalList = dataSources.value[currentTab.value];
  const isLoadingSearch = computed(() => apiLoading.value && searchVal.value !== '');
  const isLoadingMore = computed(() => apiLoading.value && searchVal.value === '');

  const { infinityScroll, infinityScrollToTop } = useInfiniteScrollHandler(apiLoading, emit);

  const baseOptionList = [
    { value: 'stay-on-page', title: 'Stay on page' },
    { value: 'open-page', title: 'Open page' },
    { value: 'scroll-to', title: 'Scroll to' },
    { value: 'open-popup', title: 'Open popup' },
    { value: 'send-email', title: 'Send email' },
    { value: 'call-phone', title: 'Make phone call' },
  ];

  const pickLinkOptionList = computed(() => {
    const cloneBaseOptionList = cloneDeepObject(baseOptionList);
    if (props.isAddedOptionGoToSalesPage) {
      cloneBaseOptionList.unshift({ value: 'go-to-sales-page', title: 'Go to Sales page' });
    }

    return cloneBaseOptionList.filter((el) => !hiddenOptions.value?.[el.value || '']);
  });

  const pageTypesOptions = computed(() => {
    return isInstant.value
      ? [{ title: 'Pages', value: 'pages' }]
      : [
          { title: 'Landing pages', value: 'pages' },
          { title: 'Collection pages', value: 'collections' },
          { title: 'Product pages', value: 'products' },
          { title: 'Blog posts', value: 'articles' },
        ];
  });

  const dataScrollTo: ComputedRef<List[]> = computed(() =>
    dataSources.value.scrollToList?.map((el: any, i: number) => ({
      title: el.isShopifySection ? 'Shopify section' : el?.name ?? `Section ${i + 1}`,
      id: `#${el.id}`,
      handle: `#${el.id}`,
      isDisabled: el.isShopifySection,
      tooltip: el.isShopifySection ? `Scroll to Shopify section<br /> is not supported` : '',
    })),
  );
  const listScrollTo = computed(() => [
    { id: '#scroll-to-top', title: 'Top', handle: '#scroll-to-top' },
    ...dataScrollTo.value,
  ]);

  const isCustomLink = computed(() => {
    return isTypeOpenPage.value && getCurrentPageTabByLink(val.value?.link ?? '') === '';
  });

  const isTypeOpenPage = computed(() => typeLink.value === 'open-page');
  const isTypeScrollTo = computed(() => typeLink.value === 'scroll-to');

  const pageLinkList = computed(() => {
    if (!list.value.length && isValidLink.value && suggestionLinks.value.length) return suggestionLinks.value;

    return isTypeScrollTo.value ? listScrollTo.value : list.value;
  });

  const settingTitle = computed(() => {
    return { en: `${isTypeScrollTo.value ? 'Section' : 'Page/link'}` };
  });

  const label = computed(() => {
    return { en: `${isTypeScrollTo.value ? 'Scroll To' : 'Pages'}` };
  });

  const helpSettingTitle = computed(() => {
    return isTypeScrollTo.value ? undefined : { content: 'Choose page or link' };
  });

  const getLinkHandle = (link: string) => {
    return link.replace(/\/(pages|products|collections|blogs)\//g, '/').replace(/^\//, '');
  };

  const currentHandle = computed(() => {
    if (isTypeOpenPage.value) {
      return val.value?.link?.replace(`/${currentTab.value === 'articles' ? 'blogs' : currentTab.value}/`, '');
    }
    return val.value?.link;
  });

  const activePageTypeOption = computed(() => {
    return currentTab.value;
  });

  const isActivePageItem = (handle: string) => {
    return handle === currentHandle.value;
  };

  const onOpenPageLink = (handle: string) => {
    if (!handle) return;

    const { isValid } = useDomainAutoComplete(handle);
    if (isValid) {
      window.open(`${handle}`, '_blank');
      return;
    }
    const pageData = isInstant.value ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[currentTab.value]?.pathName || '';
    const pageHandle = `${basePathName}/${handle}`;
    window.open(`https://${shopDomain.value}${pageHandle}`, '_blank');
  };

  const displayComboIcon = computed(() => {
    if (!val.value?.link) return '';

    if (isTypeOpenPage.value) return 'polaris-link';
    if (isTypeScrollTo.value) return 'polaris-layout-section';

    return '';
  });

  const displayComboValue = computed(() => {
    if (!val.value) return '';

    const linkHandle = getLinkHandle(val.value?.link ?? '');
    const tab = getCurrentPageTabByLink(val.value?.link ?? '');
    const listBaseType = val.value?.type === 'scroll-to' ? listScrollTo.value : dataSources.value[tab || 'pages'];
    const title = listBaseType && listBaseType?.find((el: any) => el.handle === linkHandle)?.title;
    return title ?? linkHandle;
  });

  const changePageType = (val: string) => {
    emit('change-tab-with-search', val, searchVal.value);
  };

  const popupList: ComputedRef<any[]> = computed(() =>
    dataSources.value.popup.map((el: any) => ({ title: el.name, value: `#el-${el.id}` })),
  );

  const typeLinkToCurrentTab = (type: string) => {
    switch (type) {
      case 'open-page':
        return 'pages';
      case 'scroll-to':
        return 'listScrollTo';
      case 'open-popup':
        return 'popup';
      default:
        return 'pages';
    }
  };

  const getCurrentPageTabByLink = (link: string) => {
    if (link?.startsWith('/pages')) {
      return 'pages';
    } else if (link?.startsWith('/products')) {
      return 'products';
    } else if (link?.startsWith('/collections')) {
      return 'collections';
    } else if (link?.startsWith('/blogs')) {
      return 'articles';
    }
    return '';
  };

  const checkTypeLink = () => {
    if (searchVal.value) return;
    typeLink.value = val.value?.type || useParseLinkToType(val.value?.link ?? '');
    !val.value?.type && setTabSelected(typeLinkToCurrentTab(typeLink.value));
    if (typeLink.value === 'open-page') {
      const tab = getCurrentPageTabByLink(val.value?.link ?? '');
      setTabSelected(tab || 'pages');
    }
  };

  const changeMoreSettings = (_id: string, value?: any) => {
    const data = { ...val.value, [_id]: value };
    emit('control-change', data);
  };

  const changeLink = (value: any) => {
    debounce(() => {
      emit('control-change', { ...val.value, link: value, type: typeLink.value });
    }, 0);
  };

  const SHOPIFY_PAGE_DATA: {
    [key: string]: { title: string; uri: string; pathName: string };
  } = {
    collections: {
      title: 'Collection',
      uri: `https://${shopDomain.value}/collections`,
      pathName: '/collections',
    },
    products: {
      title: 'Product',
      uri: `https://${shopDomain.value}/products`,
      pathName: '/products',
    },
    articles: {
      title: 'Articles',
      uri: `https://${shopDomain.value}/blogs`,
      pathName: '/blogs',
    },
    pages: {
      title: 'Page',
      uri: `https://${shopDomain.value}/pages`,
      pathName: '/pages',
    },
  };

  const INSTANT_PAGE_DATA: {
    [key: string]: { title: string; uri: string; pathName: string };
  } = {
    pages: {
      title: 'Page',
      uri: `https://${shopDomain.value}/`,
      pathName: '',
    },
  };

  const onClickPageListItem = (item: { handle: string; id: string; isDisabled?: boolean }) => {
    if (item?.isDisabled || isActivePageItem(item.handle)) return;

    isTypeOpenPage.value ? changePageUrl(item.handle) : changeLink(item.id);
  };

  const changePageUrl = (value: Maybe<string>) => {
    const pageData = isInstant.value ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[currentTab.value]?.pathName || '';
    const handle = !list.value.length && isValidLink.value ? value : `${basePathName}/${value}`;
    changeLink(handle);
  };

  const sortSelectedPageList = () => {
    isHideDropdownPageType.value = false;
    nextTick(() => {
      list.value.sort((a: any, b: any) => {
        if (a.handle === currentHandle.value) return -1;
        if (b.handle === currentHandle.value) return 1;
        return 0; // Keep the relative order of items if neither matches
      });
      if (isCustomLink.value && val.value?.link) {
        suggestionLinks.value = [];
        if (list.value?.some((item: any) => item.id === 'custom-link')) return;
        list.value = [
          {
            id: 'custom-link',
            title: val.value.link,
            handle: val.value.link,
          },
          ...list.value,
        ];
      } else {
        list.value = list.value.filter((item: any) => item.id !== 'custom-link');
      }
    });
  };

  const handleOpenPopoverPages = () => {
    onFocusSearchBox();
    if (!isTypeOpenPage.value) return;

    sortSelectedPageList();
  };

  const handleClosePopover = () => {
    if (!isTypeOpenPage.value) return;
    validateSearchBox();
  };

  const handleChangeType = (value: any) => {
    typeLink.value = value;
    setTabSelected(typeLinkToCurrentTab(value));
    changeLink('');
    onClearSearchBox();
  };

  const afterClearSearchBox = () => {
    setTabSelected(currentTab.value);
    infinityScrollToTop();
    onFocusSearchBox();
    list.value = dataSources.value[currentTab.value];
    sortSelectedPageList();
  };

  const handleClearCombo = () => {
    changeLink('');
    resetSort();
  };

  const resetSort = () => {
    if (!originalList.length) return;

    list.value = [...originalList];
  };

  const setTabSelected = (tab: string) => {
    emit('set-tab-selected', tab);
  };

  const searchLinkData = computed(() => {
    return { linkValue: val.value, isTypeOpenPage: isTypeOpenPage.value, list: list.value };
  });

  const {
    searchVal,
    isValidLink,
    refInputSearch,
    suggestionLinks,
    isFocusInputSearch,
    isHideDropdownPageType,
    onInputSearchBox,
    onFocusSearchBox,
    onEnterSearchBox,
    onClearSearchBox,
    validateSearchBox,
  } = useSearchLink(searchLinkData, emit, afterClearSearchBox, onClickPageListItem, sortSelectedPageList);

  const createPopup = () => {
    emit('create-popup');
  };

  onMounted(() => {
    list.value = dataSources.value[currentTab.value];
    checkTypeLink();
  });

  watch(val, () => {
    checkTypeLink();
  });

  watch(activePageTypeOption, (newVal) => {
    newVal && infinityScrollToTop();
  });

  watch(currentTab, () => {
    list.value = dataSources.value[currentTab.value];
  });

  watch(dataSources, () => {
    list.value = dataSources.value[currentTab.value];
  });

  return {
    val,
    comboDisplay: computed(() => {
      return {
        title: settingTitle.value,
        label: label.value,
        helpTitle: helpSettingTitle.value,
        icon: displayComboIcon.value as IconName,
        display: displayComboValue.value,
        close: handleClosePopover,
        open: handleOpenPopoverPages,
        clear: handleClearCombo,
      };
    }),
    typeLink,
    searchVal,
    popupList,
    pageLinkList,
    isLoadingMore,
    isTypeScrollTo,
    isTypeOpenPage,
    infinityScroll,
    refInputSearch,
    isLoadingSearch,
    pageTypesOptions,
    pickLinkOptionList,
    isFocusInputSearch,
    isEnableMoreSetting,
    activePageTypeOption,
    isHideDropdownPageType,
    createPopup,
    changeLink,
    changePageType,
    onOpenPageLink,
    handleChangeType,
    onEnterSearchBox,
    onInputSearchBox,
    onClearSearchBox,
    isActivePageItem,
    changeMoreSettings,
    onClickPageListItem,
  };
};
