import type { ScreenType, ShadowProps, StateType } from '@gem/common';
import { computed, ref } from 'vue';
import { optionApplyFor, stylePresets } from '../const';
import type {
  StateSettingValue,
  TOpiton,
  VariantBaseSetting,
  VariantPreset,
  VariantPresetItemProps,
  VariantPresetSettingValue,
} from '../types';

export const useVariantPresetControl = (props: VariantPresetItemProps, emit: any) => {
  const variantValue = computed(() => props.value);
  const currentOptionType = computed(() => props.name);
  const initVariant = computed(() => {
    const hasValue = variantValue.value?.find((v) => v.optionName === currentOptionType.value);
    if (hasValue) {
      return hasValue;
    }

    // If not found, return the base variant
    return variantValue.value?.find((v) => v.optionName === 'base');
  });
  const isHideOption = computed(() => !!initVariant.value?.hide);
  const currStyleSelected = ref(initVariant.value?.optionType || '');
  const optionsConfigured = computed(() => {
    return initVariant.value?.presets?.[currStyleSelected.value as string];
  });
  const currentVariant = computed(() => {
    return variantValue.value?.find((v) => v.optionName === currentOptionType.value);
  });
  const isShowGapSetting = computed(() => optionApplyFor.spacing?.includes(currStyleSelected.value || ''));
  const isShowConfigureButton = computed(() => optionApplyFor.configureButton?.includes(currStyleSelected.value || ''));
  const isShowTextColor = computed(() => !optionApplyFor.textColorDisable?.includes(currStyleSelected.value || ''));
  const isShowBgColor = computed(() => currStyleSelected.value !== 'color');
  const isDisableShowHideButton = computed(() => {
    const hideLength = variantValue.value?.filter((v) => v?.hide === true)?.length || 0;
    return !isHideOption.value && hideLength + 1 === props.optionNumber;
  });

  const emitChangeDataSwatches = (presets: VariantPreset[]) => {
    emit('controlChange', presets, {
      currStyleSelected: currStyleSelected.value,
      optionName: props.name,
    });
  };

  function openSwatchesModal() {
    emit('openSwatchesModal', currStyleSelected.value === 'color' ? 'color' : 'image', props.name);
  }

  function createVariantPresetBy(variantPresets: VariantPreset[], key: TOpiton, value?: VariantPresetSettingValue) {
    let change = {};

    if (['fullWidth', 'width'].includes(key)) {
      const configuredWidth = optionsConfigured.value?.width as Record<ScreenType, string>;
      const currentWidth = configuredWidth[props.currentScreen];

      if (currentWidth) {
        if (!configuredWidth.tablet) {
          configuredWidth.tablet = currentWidth;
        }
        if (!configuredWidth.mobile) {
          configuredWidth.mobile = currentWidth;
        }
      }

      change = {
        [key]: {
          ...(key === 'width' ? configuredWidth : optionsConfigured.value?.fullWidth),
          [props.currentScreen]: value,
        },
      };
    } else if (['optionShadow'].includes(key)) {
      const newShadowValue = value as Record<StateType, ShadowProps>;
      const configuredHasShadow = {
        ...optionsConfigured.value?.optionHasShadow,
        normal: !!newShadowValue?.normal?.enable,
        hover: !!newShadowValue?.hover?.enable,
        active: !!newShadowValue?.active?.enable,
      };
      change = {
        [key]: value,
        optionHasShadow: configuredHasShadow,
      };
    } else {
      change = {
        [key]: value,
      };
    }

    const preset = {
      optionName: props.name,
      optionType: currStyleSelected.value,
      presets: {
        ...initVariant.value?.presets,
        [currStyleSelected.value as string]: {
          ...optionsConfigured.value,
          ...change,
        },
      },
    } as VariantPreset;

    variantPresets.push(preset);
  }

  function updateListVariantBy(variantPresets: VariantPreset[], key: TOpiton, value?: VariantPresetSettingValue) {
    variantPresets.map((nv) => {
      if (nv.optionName === props.name) {
        nv.optionName = props.name;
        nv.optionType = currStyleSelected.value as string;

        let change = {};

        if (['fullWidth', 'width'].includes(key)) {
          const configuredWidth = optionsConfigured.value?.width as Record<ScreenType, string>;
          const currentWidth = configuredWidth[props.currentScreen];

          if (currentWidth) {
            if (!configuredWidth.tablet) {
              configuredWidth.tablet = currentWidth;
            }
            if (!configuredWidth.mobile) {
              configuredWidth.mobile = currentWidth;
            }
          }

          change = {
            [key]: {
              ...(key === 'width' ? configuredWidth : optionsConfigured.value?.fullWidth),
              [props.currentScreen]: value,
            },
          };
        } else if (['optionShadow'].includes(key)) {
          const newShadowValue = value as Record<StateType, ShadowProps>;
          const configuredHasShadow = {
            ...optionsConfigured.value?.optionHasShadow,
            normal: !!newShadowValue?.normal?.enable,
            hover: !!newShadowValue?.hover?.enable,
            active: !!newShadowValue?.active?.enable,
          };
          change = {
            [key]: value,
            optionHasShadow: configuredHasShadow,
          };
        } else {
          change = {
            [key]: value,
          };
        }

        nv.presets = {
          ...initVariant.value?.presets,
          [currStyleSelected.value as string]: {
            ...optionsConfigured.value,
            ...change,
          },
        } as Record<string, VariantBaseSetting>;
      }
    });

    return variantPresets;
  }

  const changeVariantPreset = (key: TOpiton, value?: VariantPresetSettingValue, onChange?: boolean) => {
    let newVariants: VariantPreset[] = [];
    if (variantValue.value) {
      newVariants = [...variantValue.value];
    }

    if (!currentVariant.value) {
      createVariantPresetBy(newVariants, key, value);
    } else {
      newVariants = updateListVariantBy(newVariants, key, value);
    }

    if (onChange) {
      emit('controlOnChange', newVariants);
    } else {
      emitChangeDataSwatches(newVariants);
    }
  };

  function handleInputChange(type: 'change' | 'onChange', key: TOpiton, value?: string | number) {
    changeVariantPreset(key, value, type === 'onChange');
  }

  function createVariantPreset(variantPresets: VariantPreset[], hide?: boolean) {
    const preset = {
      optionName: props.name,
      optionType: currStyleSelected.value,
      hide: hide,
      presets: {
        ...initVariant.value?.presets,
        [currStyleSelected.value as string]: {
          ...optionsConfigured.value,
        },
      },
    } as VariantPreset;

    variantPresets.push(preset);
  }

  function updateListVariantPreset(variantPresets: VariantPreset[], hide?: boolean) {
    variantPresets.map((nv) => {
      if (nv.optionName === props.name) {
        nv.optionName = props.name;
        nv.optionType = currStyleSelected.value as string;
        nv.hide = hide;
        nv.presets = {
          ...initVariant.value?.presets,
          [currStyleSelected.value as string]: {
            ...optionsConfigured.value,
          },
        } as Record<string, VariantBaseSetting>;
      }
    });

    return variantPresets;
  }

  function handleShowHide() {
    if (!variantValue.value) return;
    if (!props.name) return;
    if (!props.optionNumber) return;

    const optionNames = [...props.name, 'base'];
    const variantByOptionNames = variantValue.value?.filter((v) => optionNames?.includes(v?.optionName));

    const variantInHide = variantByOptionNames.filter((v) => v?.hide === true);
    if (variantByOptionNames.length === props.optionNumber) {
      if (variantInHide.length === variantByOptionNames.length - 1 && !initVariant.value?.hide) {
        return;
      }
    }

    if (variantByOptionNames.length > props.optionNumber) {
      if (variantInHide.length === variantByOptionNames.length - 2 && !initVariant.value?.hide) {
        return;
      }
    }

    let newVariants: VariantPreset[] = [];
    if (variantValue.value) {
      newVariants = [...variantValue.value];
    }

    if (!currentVariant.value) {
      createVariantPreset(newVariants, !initVariant.value?.hide);
    } else {
      newVariants = updateListVariantPreset(newVariants, !initVariant.value?.hide);
    }

    emit('controlChange', newVariants);
    emitChangeDataSwatches(newVariants);
  }

  const updateVariantStyleStateSetting = ({
    type,
    settingID,
    data,
    currentState,
  }: {
    type: 'change' | 'onChange';
    settingID: TOpiton;
    data?: StateSettingValue;
    currentState?: string;
  }) => {
    let newData = optionsConfigured.value?.[settingID as TOpiton] as any;
    if (typeof newData !== 'string') {
      newData = {
        ...newData,
        [currentState || 'normal']: data,
      };
      changeVariantPreset(settingID, newData, type === 'onChange');
    }
  };

  const handleChangeStateSetting = (settingID: TOpiton, data?: StateSettingValue, currentState?: string) => {
    updateVariantStyleStateSetting({
      type: 'change',
      settingID,
      data,
      currentState,
    });
  };

  const handleOnChangeStateSetting = (settingID: TOpiton, data?: StateSettingValue, currentState?: string) => {
    updateVariantStyleStateSetting({
      type: 'onChange',
      settingID,
      data,
      currentState,
    });
  };

  function setVariantStyle(value: string) {
    currStyleSelected.value = value;

    let newVariants: VariantPreset[] = [];
    if (variantValue.value) {
      newVariants = [...variantValue.value];
    }

    if (!currentVariant.value) {
      createVariantPreset(newVariants);
    } else {
      newVariants = updateListVariantPreset(newVariants);
    }

    emitChangeDataSwatches(newVariants);
  }

  const getComboProps = (settingId: string, parentSettingId?: string) => {
    const optionName = props.name?.toLowerCase().replace(' ', '-');
    return {
      ...props,
      comboID: `${optionName}-${settingId}`,
      comboParentID: parentSettingId ? `${optionName}-${parentSettingId}` : `${props.comboParentID}-${settingId}`,
      comboRootParentID: parentSettingId ? `${optionName}-${parentSettingId}` : ``,
    };
  };

  const getBgColorStateValue = (state?: StateType) => {
    const currentState = state ?? 'normal';
    return optionsConfigured.value?.optionBgColor?.[currentState] ?? undefined;
  };

  const getTextColorStateValue = (state?: StateType) => {
    const currentState = state ?? 'normal';
    return optionsConfigured.value?.optionTextColor?.[currentState] ?? undefined;
  };

  const getBorderStateValue = (state?: StateType) => {
    const currentState = state ?? 'normal';
    return optionsConfigured.value?.optionBorder?.[currentState] ?? undefined;
  };

  const getCornerStateValue = (state?: StateType) => {
    const currentState = state ?? 'normal';
    return optionsConfigured.value?.optionRounded?.[currentState] ?? undefined;
  };

  const getShadowStateValue = (state?: StateType) => {
    const currentState = state ?? 'normal';
    if (!optionsConfigured.value?.optionShadow?.[currentState]) return undefined;

    return {
      ...optionsConfigured.value?.optionShadow?.[currentState],
      enable: optionsConfigured.value?.optionHasShadow?.[currentState],
    };
  };

  return {
    isHideOption,
    stylePresets,
    isShowBgColor,
    isShowTextColor,
    isShowGapSetting,
    currStyleSelected,
    optionsConfigured,
    isShowConfigureButton,
    isDisableShowHideButton,
    getComboProps,
    handleShowHide,
    setVariantStyle,
    handleInputChange,
    openSwatchesModal,
    getBorderStateValue,
    getCornerStateValue,
    getShadowStateValue,
    getBgColorStateValue,
    getTextColorStateValue,
    handleChangeStateSetting,
    handleOnChangeStateSetting,
  };
};
