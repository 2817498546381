// const commonDomains = ['.com', '.co', '.net', '.org', '.io'];

export const useDomainAutoComplete = (initialInput: string) => {
  const isValidLink = (value: string): boolean => {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    return urlPattern.test(value);
  };

  const isHomePage = initialInput === '/';

  const formatWithHttps = (value: string): string => {
    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      return `https://${value}`;
    }
    return value;
  };

  const autoCompleteDomain = (value: string): { id?: string; title: string; handle: string }[] => {
    if (isHomePage) {
      return [{ title: 'Home Page', handle: '/' }];
    }

    if (isValidLink(value)) {
      return [{ id: 'custom-link', title: formatWithHttps(value), handle: formatWithHttps(value) }];
    }

    if (value.startsWith('/')) {
      return [{ title: value, handle: value }];
    }

    /* const cleanedValue = cleanInput(value);
    return commonDomains.map((domain) => ({
      title: formatWithHttps(cleanedValue + domain),
      handle: formatWithHttps(cleanedValue + domain),
    })); */

    return [];
  };

  return {
    isValid: isValidLink(initialInput) || isHomePage || initialInput.startsWith('/'),
    completedDomains: autoCompleteDomain(initialInput),
  };
};
