<script lang="ts" setup>
import type { OptionItem } from '@gem/common';
import type { PickLinkProps } from '@gem/element-setting-ui';
import { SettingLayout, OptionValue } from '@gem/element-setting-ui';
import { GButton } from '@gem/uikit-v2';
import { computed, ref } from 'vue';

type Props = {
  value?: PickLinkProps['value'];
};
const props = defineProps<Props>();
const val = computed(() => props.value);
const collapsed = ref(true);

const emit = defineEmits<{
  (e: 'control-change', key: string, value: any): void;
}>();

const newTabOptions = ref<OptionItem[]>([
  { label: 'Yes', value: '_blank' },
  { label: 'No', value: '_self' },
]);

const followingOptions = ref<OptionItem[]>([
  { label: 'Yes', value: true as any },
  { label: 'No', value: false as any },
]);
</script>

<template>
  <template v-if="!collapsed">
    <SettingLayout :label-with-lang="{ en: 'Open new tab' }">
      <template #control>
        <OptionValue
          id="target"
          class="!w-[140px]"
          :value="val?.target ?? '_self'"
          :options="newTabOptions"
          @control-change="(value) => emit('control-change', 'target', value)" />
      </template>
    </SettingLayout>
    <SettingLayout
      :label-with-lang="{ en: 'Following link' }"
      :help="{ content: 'Do not allow search <br> engine to follow through <br> this link' }">
      <template #control>
        <OptionValue
          id="noFollow"
          class="!w-[140px]"
          :value="!!val?.noFollow"
          :options="followingOptions"
          @control-change="(value) => emit('control-change', 'noFollow', value)" />
      </template>
    </SettingLayout>
  </template>
  <GButton
    data-test="editor-control-show-more-btn"
    type="secondaryGhost"
    size="medium"
    button-width="full"
    icon-view-box="0 0 16 16"
    :icon-after="collapsed ? 'polaris-chevron-down' : 'polaris-chevron-up'"
    @click="collapsed = !collapsed">
    {{ collapsed ? 'Show more' : 'Show less' }}
  </GButton>
</template>
