import { ID } from '@gem/common';
import { computed } from 'vue';

import type { ItemIconActionOptions, Item, ItemManagementEmits, ItemManagementProps } from '../../types';
import type { Component, ComponentTag } from '@gem/common';
import type { useItemManagementState } from './useItemManagementState';
import { ItemIconAction } from '../../types';

import { scrollItemIntoView } from '../../helpers';
import {
  SUPPORT_ICON_COMPONENTS,
  SUPPORT_CLICK_COMPONENTS,
  SUPPORT_HOVER_COMPONENTS,
  SUPPORT_EDITOR_INLINE_COMPONENTS,
} from '../../constants';

let timeoutChangeActiveItem: NodeJS.Timeout | null = null;

export const useItemManagementActions = (
  state: ReturnType<typeof useItemManagementState>,
  props: ItemManagementProps,
  emit: ItemManagementEmits,
) => {
  const titleList = computed(() => {
    return props?.value ?? [];
  });

  const childList = computed(() => {
    return props?.childrens ?? [];
  });

  const hasChild = computed(() => childList.value?.length > 0 && props?.tag !== 'FormDropdown');
  const hasSupportIcon = computed(() => SUPPORT_ICON_COMPONENTS.includes(props?.tag ?? ''));

  const handleEditTitleState = (id: string) => {
    state.editingId.value = id;
    state.blurringTitleList.value = [];
  };

  const handleBlurTitleState = (id: string) => {
    if (!state.blurringTitleList.value.includes(id)) state.blurringTitleList.value.push(id);

    if (
      state.editingId.value === id &&
      state.blurringTitleList.value.length === 1 &&
      state.blurringTitleList.value.includes(id)
    ) {
      state.editingId.value = '';
    }

    if (state.blurringTitleList.value.length > 1) state.blurringTitleList.value = [];
  };

  const handleActivate = (index: string) => {
    if (state.activeIds.value.includes(index)) {
      state.activeIds.value = state.activeIds.value?.filter((v) => v !== index);
    } else {
      state.activeIds.value = [...state.activeIds.value, index];
    }
  };

  const handleAppendItem = () => {
    const itemTitle = state.itemInfo.value.titleItem;

    const newTitleList = titleList.value
      ? [...titleList.value, `${itemTitle} ${hasChild.value ? titleList.value.length + 1 : ''}`]
      : [`${itemTitle} 1`];

    const newItem = createNewItem();
    const newChildrenList = hasChild.value ? childList.value?.concat(newItem) : [];

    handleEmitChange(newTitleList, newChildrenList);

    if (hasSupportIcon.value) {
      handleTriggerUpdateChildIcon(ItemIconAction.Add);
    }

    handleChangeActiveItemTimeout(String(newChildrenList ? newChildrenList.length - 1 : 1));

    scrollNewAppendedItemIntoView();
  };

  const scrollNewAppendedItemIntoView = () => {
    setTimeout(() => {
      const newAppendedItemId = (state.items.value.length - 1).toString();
      scrollItemIntoView(newAppendedItemId);
    }, 0);
  };

  const createNewItem = (): Component => {
    const template = props.childTemplate;

    return {
      uid: ID(),
      styles: {},
      settings: {},
      label: state.itemInfo.value.label,
      tag: state.itemInfo.value.tag as ComponentTag,
      childrens: template ? handleAddItemByTemplate(template) : [],
    };
  };

  const handleDuplicate = (index: string) => {
    if (!index) return;

    const itemTitle = state.itemInfo.value.titleItem;

    const itemDuplicateTitle = `${itemTitle} ${hasChild.value ? titleList.value.length + 1 : ''}`;

    titleList.value.splice(Number(index) + 1, 0, itemDuplicateTitle);

    if (hasChild.value) {
      const uid = childList.value?.[Number(index)]?.uid;
      const itemToDuplicate = childList.value.filter((i) => i.uid === uid);
      const newItem = changeIdDuplicate(itemToDuplicate);

      if (!newItem?.[0]) return;

      childList.value.splice(Number(index) + 1, 0, newItem[0]);

      handleEmitChange(titleList.value, childList.value);
    } else {
      handleEmitChange(titleList.value);
    }

    if (hasSupportIcon.value) {
      handleTriggerUpdateChildIcon(ItemIconAction.Clone, { index });
    }

    handleChangeActiveItemTimeout(String(Number(index) + 1));
  };

  const handleDelete = (index: string) => {
    if (!index) return;

    const idx = Number(index);

    const uid = childList.value?.[idx]?.uid;

    const newChild = childList.value.filter((i) => i.uid !== uid);

    const newChildrenList = hasChild.value ? newChild : [];
    const newTitleList = titleList.value.filter((_, i) => i !== idx);

    handleEmitChange(newTitleList, newChildrenList);

    if (hasSupportIcon.value) {
      handleTriggerUpdateChildIcon(ItemIconAction.Delete, { index });
    }
  };

  const handleReorderItem = (childItems: Item[], from?: number, to?: number) => {
    if ((from && from < 0) || (to && to < 0)) return;

    const newTitleList: string[] = childItems.map((v) => v.title);

    const itemToMove = childList.value.splice(from ?? 0, 1)[0];
    if (itemToMove) childList.value.splice(to ?? 0, 0, itemToMove);

    const newChildrenList = hasChild.value ? childList.value : [];

    handleEmitChange(newTitleList, newChildrenList);

    if (hasSupportIcon.value) {
      handleTriggerUpdateChildIcon(ItemIconAction.Move, { index: '', from, to });
    }
  };

  const handleSlideToIndex = (index: Number) => {
    const $iframe = document.querySelector<HTMLIFrameElement>('.iframe');
    if (!$iframe) return;

    const $win = $iframe.contentWindow;
    if (!$win || !props?.componentUid) return;

    $win.dispatchEvent(
      new CustomEvent('move-to-idx', {
        detail: {
          index,
          componentUid: props.componentUid,
        },
      }),
    );
  };

  const handleChangeActiveItemTimeout = (index: string) => {
    cleanupTimeouts();

    timeoutChangeActiveItem = setTimeout(() => {
      emit('change-active-item', 'activeItem', index);
    }, 800);
  };

  const cleanupTimeouts = () => {
    if (timeoutChangeActiveItem) {
      clearTimeout(timeoutChangeActiveItem);
    }
  };

  const handleAddItemByTemplate = (template: Component): Component[] => {
    const newData: Component[] = (template.childrens || []).map((child) => {
      let childItem: Component[] = [];

      if (child.childrens && child.childrens?.length > 0) {
        childItem = changeIdDuplicate(child.childrens);
      }

      return {
        ...child,
        uid: ID(),
        ...(hasChild.value && { childrens: childItem }),
      };
    });

    return newData;
  };

  const changeIdDuplicate = (data: Component[]): Component[] => {
    return data.map((child) => {
      let childItem: Component[] = [];

      if (child.childrens && child.childrens?.length > 0) {
        childItem = changeIdDuplicate(child.childrens);
      }

      return {
        ...child,
        uid: ID(),
        ...(hasChild.value && { childrens: childItem }),
      };
    });
  };

  const handleClick = (index: string) => {
    if (!SUPPORT_CLICK_COMPONENTS.includes(props.tag ?? '')) return;
    emit('change-active-item', 'activeItem', index);
  };

  const handleHover = (index?: string) => {
    if (!SUPPORT_HOVER_COMPONENTS.includes(props.tag ?? '')) return;
    emit('change-active-item', 'hoverItem', index);
  };

  const handleCheckDisableAction = (action: 'edit' | 'duplicate' | 'delete') => {
    if (action === 'delete') {
      return state.items.value?.length === 1;
    }
  };

  const handleCheckIsEditable = () => {
    return !SUPPORT_EDITOR_INLINE_COMPONENTS.includes(props.tag ?? '');
  };

  const handleOnChangeTitle = (value: string, index: string) => {
    const newTitle = handleNormalizeItemTitle(value);
    updateTitleList(Number(index), newTitle);
    emit('onChange', { setting: titleList.value });
  };

  const handleChangeTitle = (value: string, index: string) => {
    const newTitle = handleNormalizeItemTitle(value);
    updateTitleList(Number(index), newTitle);
    handleEmitChange(titleList.value);
  };

  const handleChangeTitleOnEvent = (value: Event | KeyboardEvent, index: string) => {
    const targetValue = (value?.target as HTMLInputElement)?.value ?? '';
    const newTitle = handleNormalizeItemTitle(targetValue);
    updateTitleList(Number(index), newTitle);
    handleEmitChange(titleList.value);
  };

  const updateTitleList = (index: number, value: string) => {
    titleList.value[index] = value;
  };

  const handleTriggerUpdateChildIcon = (action: ItemIconAction, options?: ItemIconActionOptions) => {
    emit('trigger-update-child-icon', action, options);
  };

  const handleEmitChange = (settings: string[], children?: Component[]) => {
    const payload = {
      setting: settings,
      ...(children !== undefined && { childrens: children }),
    };

    emit('change', payload);
  };

  const handleNormalizeItemTitle = (title?: string) => {
    return title?.replace(/<\/?[^>]+(>|$)/g, '')?.trim() ?? '';
  };

  return {
    handleClick,
    handleHover,
    handleDelete,
    handleActivate,
    handleDuplicate,
    handleAppendItem,
    handleReorderItem,
    handleChangeTitle,
    handleSlideToIndex,
    handleOnChangeTitle,
    handleEditTitleState,
    handleBlurTitleState,
    handleCheckIsEditable,
    handleNormalizeItemTitle,
    handleChangeTitleOnEvent,
    handleCheckDisableAction,
  };
};
