<script lang="ts" setup>
import { InputComboLayout, SettingLayout, InputText } from '@gem/element-setting-ui';
import Dropdown from '../dropdown/Dropdown.vue';
import { GButton, GTooltip } from '@gem/uikit-v2';
import { GIcon, GSvg } from '@gem/icons';
import { usePickLink } from './hooks/usePickLink';
import type { PickLinkProps } from './types';
import EmailLink from './components/EmailLink.vue';
import PhoneLink from './components/PhoneLink.vue';
import PopupLink from './components/PopupLink.vue';
import MoreSettings from './components/MoreSettings.vue';

const props = defineProps<PickLinkProps>();

const emit = defineEmits<{
  (e: 'control-search', value: string): void;
  (e: 'control-on-change', value?: any): void;
  (e: 'control-change', value?: any): void;
  (e: 'control-focus', controlId?: string | number, value?: any): void;
  (e: 'control-blur', controlId?: string | number, value?: any): void;
  (e: 'set-tab-selected', tab?: any): void;
  (e: 'change-tab-with-search', tab: any, keyword: string): void;
  (e: 'show-more'): void;
  (e: 'create-popup'): void;
}>();

const {
  val,
  typeLink,
  searchVal,
  popupList,
  comboDisplay,
  pageLinkList,
  isLoadingMore,
  isTypeScrollTo,
  isTypeOpenPage,
  infinityScroll,
  refInputSearch,
  isLoadingSearch,
  pageTypesOptions,
  pickLinkOptionList,
  isFocusInputSearch,
  isEnableMoreSetting,
  activePageTypeOption,
  isHideDropdownPageType,
  createPopup,
  changeLink,
  changePageType,
  onOpenPageLink,
  handleChangeType,
  onEnterSearchBox,
  onInputSearchBox,
  onClearSearchBox,
  isActivePageItem,
  changeMoreSettings,
  onClickPageListItem,
} = usePickLink(props, emit);
</script>
<template>
  <div class="gemx-control">
    <div class="flex flex-col gap-16">
      <SettingLayout v-if="!isHiddenOpenLinkSelect" :label-with-lang="customLabel ?? { en: 'After click' }">
        <template #control>
          <Dropdown
            id="type"
            :options="pickLinkOptionList"
            :value="typeLink"
            :is-full-width="true"
            @control-change="(value) => handleChangeType(value)" />
        </template>
      </SettingLayout>
      <div
        v-if="isTypeOpenPage || isTypeScrollTo"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-page-link">
        <SettingLayout :label-with-lang="comboDisplay.title" :help="comboDisplay.helpTitle">
          <template #control>
            <InputComboLayout
              placeholder="Add..."
              :label="comboDisplay.label"
              :show-label="false"
              :combo-icon="comboDisplay?.icon"
              :content-display="comboDisplay.display"
              :is-full-width="true"
              :level="level || 0"
              :combo-i-d="comboID || ''"
              :combo-parent-i-d="comboParentID || ''"
              :combo-root-parent-i-d="comboRootParentID || ''"
              @close="comboDisplay.close"
              @clear="comboDisplay.clear"
              @click="comboDisplay.open">
              <template #default="{ close }">
                <div class="flex flex-col gap-12">
                  <InputText
                    id="search"
                    ref="refInputSearch"
                    type="text"
                    :placeholder="`${isTypeOpenPage ? 'Insert link or search' : 'Search section on this page'}`"
                    prefix-icon="polaris-search"
                    :value="searchVal"
                    :clear-button="true"
                    :active="isFocusInputSearch"
                    @focusout="() => (isFocusInputSearch = false)"
                    @clear="onClearSearchBox"
                    @enter="() => onEnterSearchBox(close)"
                    @control-on-change="onInputSearchBox" />
                  <Dropdown
                    v-if="isTypeOpenPage && !isHideDropdownPageType"
                    id="pageType"
                    :is-full-width="true"
                    :options="pageTypesOptions"
                    :value="activePageTypeOption"
                    blank-text="Custom url"
                    :disabled="!activePageTypeOption && searchVal !== ''"
                    @control-change="(value) => changePageType(value)" />
                  <perfect-scrollbar ref="infinityScroll" class="h-full" :options="{ suppressScrollX: true }">
                    <div class="min-h-[160px] pr-8" :class="isHideDropdownPageType ? 'h-[396px]' : 'h-[348px]'">
                      <div v-if="isLoadingSearch" class="flex w-full items-center justify-center py-16">
                        <GIcon name="gp-loading" :spin-infinity="true" />
                      </div>
                      <div
                        v-if="pageLinkList?.length === 0 && !isLoadingMore"
                        class="flex h-full min-h-[250px] flex-col items-center justify-center gap-8">
                        <GSvg name="gp-no-result" :width="80" :height="80" />
                        <p class="text-text-dark-100 text-12 text-center font-semibold leading-5">
                          No page match your search.<br />
                          Try another keyword or insert link
                        </p>
                      </div>
                      <template v-else>
                        <div
                          v-for="item in pageLinkList"
                          :key="item.id"
                          class="text-12 group flex h-36 w-full items-center justify-between gap-8 truncate rounded-xl px-8"
                          :class="{
                            'hover:bg-dark-250 relative': !item?.isDisabled,
                            'cursor-not-allowed': item?.isDisabled,
                            'cursor-pointer': !item?.isDisabled,
                          }"
                          @click="() => onClickPageListItem(item)">
                          <div class="flex w-full" :class="{ 'max-w-[116px]': isTypeScrollTo }">
                            <div class="min-w-[28px]">
                              <div
                                v-if="isActivePageItem(item.handle)"
                                class="flex h-[20px] items-center justify-center">
                                <GSvg name="gp-checkmark" :width="20" :height="20" />
                              </div>
                            </div>
                            <div
                              class="max-w-[calc(100%_-_32px)] truncate"
                              :class="[`text-text-dark-${item?.isDisabled ? '100' : isTypeOpenPage ? '300' : '500'}`]">
                              <GTooltip
                                placement="top"
                                :disabled="!item?.tooltip"
                                :wrapper-class="`truncate`"
                                display-type="block">
                                {{ item.title }}
                                <template v-if="item?.tooltip" #content>
                                  <div
                                    class="text-text-dark-500 text-12 w-[150px] leading-5"
                                    v-html="item?.tooltip"></div>
                                </template>
                              </GTooltip>
                            </div>
                          </div>
                          <p v-if="isTypeScrollTo" :class="[`text-text-dark-${item?.isDisabled ? '100' : '300'}`]">
                            {{ `${item.id === '#scroll-to-top' ? '' : ` ID:`} ${item.id}` }}
                          </p>
                          <div
                            v-if="isTypeOpenPage && !item?.isDisabled"
                            class="bg-dark-250 invisible absolute top-0 right-0 flex h-full items-center justify-center rounded-xl px-8 group-hover:visible">
                            <GButton
                              type="link"
                              size="normal"
                              icon-view-box="0 0 14 14"
                              @click.stop="onOpenPageLink(item.handle)">
                              View</GButton
                            >
                          </div>
                        </div>
                      </template>
                      <div v-if="isLoadingMore" class="flex w-full items-center justify-center py-16">
                        <GIcon name="gp-loading" :spin-infinity="true" />
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </template>
            </InputComboLayout>
          </template>
        </SettingLayout>
      </div>
      <PopupLink
        v-if="typeLink === 'open-popup'"
        :value="val?.link"
        :popup-list="popupList"
        @create-popup="createPopup"
        @change-popup="(value: string) => changeLink(value)" />
      <EmailLink
        v-if="typeLink === 'send-email'"
        :value="val?.link"
        @change-mailto="(value: string) => changeLink(value)" />
      <PhoneLink
        v-if="typeLink === 'call-phone'"
        :value="val?.link"
        @change-phone="(value: string) => changeLink(value)" />
      <MoreSettings
        v-if="isEnableMoreSetting"
        :value="val"
        @control-change="(key, value) => changeMoreSettings(key, value)" />
    </div>
  </div>
</template>
