import { DEFAULT_UNIT, parseUnit } from '@gem/common';
import { ref } from 'vue';
import type { SpacingGlobalType } from '../types';

export const useGlobalStyleSpacing = (globalSpacingOptions: any) => {
  const spacing = ref<SpacingGlobalType>({
    auto: 'auto',
    xxs: '--g-s-xxs',
    xs: '--g-s-xs',
    s: '--g-s-s',
    m: '--g-s-m',
    l: '--g-s-l',
    xl: '--g-s-xl',
    ['2xl']: '--g-s-2xl',
    ['3xl']: '--g-s-3xl',
    ['4xl']: '--g-s-4xl',
    ['5xl']: '--g-s-5xl',
  });

  const getStringValueFromGlobal = (value?: string) => {
    if (!value) return undefined;
    const [textValue, unit] = parseUnit(value);
    if (value === 'auto') return 'auto';
    if (textValue) {
      if (!isNaN(Number(textValue || '')) && (unit === 'px' || !DEFAULT_UNIT.includes(unit)))
        return textValue?.toString();
      else return value;
    } else {
      if (value === 'auto') return 'auto';
      return Object.keys(spacing.value).find(
        (key) => spacing.value[key as keyof SpacingGlobalType] === value.slice(4, value.length - 1),
      );
    }
  };

  const getDisplayValue = (val: string | undefined) => {
    if (typeof val === 'undefined') return '0';
    if (val === 'auto') return 'Auto';
    const valueGlobal = globalSpacingOptions?.find((item: any) => item.id === val);
    if (valueGlobal) {
      return valueGlobal?.value;
    } else {
      return val ?? '0';
    }
  };

  const getValueByCssVar = (val?: string) => {
    return getDisplayValue(getStringValueFromGlobal(val));
  };

  return { spacing, getDisplayValue, getStringValueFromGlobal, getValueByCssVar };
};
