<script setup lang="ts">
import type { ControlInputAndSliderTypes } from './type';
import { useInputAndSlider } from './hooks/useInputAndSlider';
import InputUnitWithDropdown from './InputUnitWithDropdown.vue';
import Slider from './components/Slider.vue';

const props = withDefaults(defineProps<ControlInputAndSliderTypes>(), {
  min: 0,
  max: 500,
  step: 1,
});
const emit = defineEmits<{
  (e: 'controlChange', value?: any): void;
  (e: 'controlOnChange', value?: any): void;
}>();

const { sliderVal, inputVal, handleControlOnChange, handleControlChange, onInputChange } = useInputAndSlider(
  props,
  emit,
);
</script>
<template>
  <div class="flex items-center gap-8" data-test="editor-control-input-slider">
    <div v-if="!hideInput" class="min-w-[48px] max-w-[62px]">
      <InputUnitWithDropdown
        v-bind="props"
        :value="inputVal"
        :disablePreviousInputValue="true"
        :max="ignoreMax ? undefined : max"
        @control-change="(value) => onInputChange(value, 'change')"
        @control-on-change="(value) => onInputChange(value, 'onChange')" />
    </div>
    <div class="relative flex w-full items-center">
      <Slider
        :key="`${min}-${max}`"
        :min="min"
        :max="max"
        :ignore-max="ignoreMax"
        :step="step"
        :model-value="sliderVal"
        :full-width="hideInput"
        @control-change="handleControlChange"
        @control-on-change="handleControlOnChange" />
    </div>
  </div>
</template>
