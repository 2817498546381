import type { GDropdownMenuItemProps } from '@gem/uikit-v2';

export const optionApplyFor = {
  width: ['dropdown', 'rectangle_list', 'image_shopify', 'color', 'image'],
  fullWidth: ['dropdown'],
  height: ['dropdown', 'rectangle_list', 'image_shopify', 'color', 'image'],
  spacing: ['rectangle_list', 'image_shopify', 'color', 'image'],
  configureButton: ['color', 'image'],
  labelToggle: [],
  labelTextSize: [],
  labelFontWeight: [],
  labelTransform: [],
  numberPerRow: [],
  itemSpacing: [],
  textColorDisable: ['image_shopify', 'color', 'image'],
};

export const stylePresets: GDropdownMenuItemProps[] = [
  {
    title: 'Dropdown',
    value: 'dropdown',
    svgIcon: 'gp-variant-preset-style-dropdown',
    svgIconSelected: 'gp-variant-preset-style-dropdown-selected',
  },
  {
    title: 'Button selection',
    value: 'rectangle_list',
    svgIcon: 'gp-variant-preset-style-list',
    svgIconSelected: 'gp-variant-preset-style-list-selected',
  },
  {
    title: 'Shopify image',
    value: 'image_shopify',
    svgIcon: 'gp-variant-preset-style-image-shopify',
    svgIconSelected: 'gp-variant-preset-style-image-shopify-selected',
  },
  {
    title: 'Color swatch',
    value: 'color',
    svgIcon: 'gp-variant-preset-style-color',
    svgIconSelected: 'gp-variant-preset-style-color-selected',
  },
  {
    title: 'Upload image',
    value: 'image',
    svgIcon: 'gp-variant-preset-style-image',
    svgIconSelected: 'gp-variant-preset-style-image-selected',
  },
];
