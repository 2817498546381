import { computed, watch } from 'vue';
import type { ImageSelectorEmits, ImageSelectorProps } from '../types';
import { useCreateImageSelectContext } from './useCreateImageSelectContext';

export const useImageSelector = (props: ImageSelectorProps, emit: ImageSelectorEmits) => {
  const { state, actions } = useCreateImageSelectContext(props, emit);

  const {
    isLoaded,
    imageSrc,
    imageList,
    wrapImage,
    showAlert,
    alertType,
    imageWidth,
    isUploading,
    isLinkValid,
    imageHeight,
    isLinkHttps,
    isOpenGallery,
    deletedImageList,
  } = state;

  const { handleTriggerImageChange, handleSetImageList, openGallery } = actions;

  const isCDNImage = computed(() => {
    const src = imageSrc.value;
    return src.includes('cdn.shopify.com') && !src.includes('.tiff') && !src.includes('.tif');
  });

  const previewImageSrc = computed(() =>
    isCDNImage.value ? imageSrc.value.replace(/(_large)?(\.[^.]+)$/, '_220x220$2') : imageSrc.value ?? '',
  );

  const imageClasses = computed(() => {
    if (wrapImage?.value) return '';

    const classes = [];

    if (!isUploading.value) {
      classes.push('h-full w-full object-cover');
    }

    if (!isLinkValid.value || !isLinkHttps.value) {
      classes.push('!mx-auto !h-auto !w-auto');
    }

    const height = Number(imageHeight.value);
    const width = Number(imageWidth.value);

    if (height < 100 && width < 100) {
      classes.push('h-auto w-auto object-contain');
      return classes.join(' ');
    }

    const wrapperWidth = wrapImage?.value?.clientWidth;
    const wrapperHeight = wrapImage?.value?.clientHeight;

    if (!wrapperWidth || !wrapperHeight) return classes.join(' ');

    const aspectRatio = width / height;
    const wrapperAspectRatio = wrapperWidth / wrapperHeight;

    classes.push(aspectRatio < wrapperAspectRatio ? 'h-full w-auto object-contain' : 'w-full h-auto object-contain');

    return classes.join(' ');
  });

  const errorMessage = computed(() => {
    if (!isLinkValid.value) return 'Image source is invalid.';
    if (!isLinkHttps.value) return 'Image source must start with https://';
    return undefined;
  });

  watch(
    () => props.isUploading,
    () => {
      state.isUploading.value = props.isUploading;
    },
  );

  watch(
    () => props.gallery?.imageToUpload?.filePath,
    () => {
      if (!props.gallery?.imageToUpload) return;

      const {
        width = 0,
        height = 0,
        fileName = '',
        filePath = '',
        storage = 'THEME',
        backupFileKey = '',
        backupFilePath = '',
      } = props.gallery.imageToUpload;

      handleTriggerImageChange({
        width,
        height,
        fileName,
        filePath,
        storage,
        backupFileKey,
        backupFilePath,
      });
    },
  );

  return {
    imageSrc,
    isLoaded,
    showAlert,
    alertType,
    imageList,
    errorMessage,
    imageClasses,
    isOpenGallery,
    previewImageSrc,
    deletedImageList,
    openGallery,
    handleSetImageList,
  };
};
