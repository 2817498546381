<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core';
import { ref, computed } from 'vue';
import { GButton } from '@gem/uikit-v2';
import IconUploadEmpty from './IconUploadEmpty.vue';
import IconPreview from './IconPreview.vue';

type Props = {
  id?: string | number;
  data: any;
  loading?: boolean;
  selectedItem: any;
  styleIcon?: string;
  isActiveUploaded?: boolean;
  deleteIds?: string[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'on-scroll'): void;
  (e: 'on-select', value: string, id: string, name: string): void;
  (e: 'active-uploaded'): void;
  (e: 'mode-delete', val: boolean): void;
  (e: 'bulk-select-icon', id: string): void;
}>();

const iconBoundary = ref();
const isActive = computed(() => props.isActiveUploaded);
const data = computed(() => (!isActive.value ? props.data.slice(0, 5) : props.data));
const isShowViewAll = computed(() => !isActive.value && props.data.length > 5);

useInfiniteScroll(
  iconBoundary,
  () => {
    emit('on-scroll');
  },
  { distance: 20 },
);

function readSVG(url: string, id?: string | undefined) {
  return fetch(url)
    .then((response) => response.text())
    .then((data) => {
      const serialize = new XMLSerializer();

      const domParserData = new DOMParser().parseFromString(data, 'image/svg+xml');
      const svgDom = domParserData.querySelector('svg');

      // add viewbox
      if (!svgDom?.getAttribute('viewBox') && svgDom?.getAttribute('width') && svgDom?.getAttribute('height')) {
        svgDom.setAttribute('viewBox', `0 0 ${svgDom.getAttribute('width')}  ${svgDom.getAttribute('height')}`);
      }
      let stringData = serialize.serializeToString(domParserData);

      if (stringData.includes('<style') && id) {
        const styleString = stringData.match(/<style(\n|.)*?<\/style>/g);
        const regex = /(\.[^}]+\{)/g;

        // add class in style tag
        if (styleString?.length) {
          const newStyle = styleString[0].replace(regex, (val) => {
            return `.id${id} ${val}`;
          });
          let newStringData = stringData.replace(styleString[0], newStyle);
          // add class to svg
          newStringData = newStringData.replace('<svg', `<svg data-id="${id}" class="id${id}" width="20" height="20"`);
          return newStringData;
        }
      }
      stringData = stringData.replace('<svg', `<svg data-id="${id}" width="20" height="20"`);
      return stringData;
    });
}

const changeUploadIcon = (path: string, id: string, name: string) => {
  if (props.selectedItem.id === id) return;
  readSVG(path, id).then((svg) => {
    emit('on-select', svg, id, name);
  });
};

const bulkDeleteIcon = (iconId: string) => {
  emit('bulk-select-icon', iconId);
};

const onClickWrapperIcon = (filePath: string, id: string, fileName: string) => {
  if (props?.deleteIds && props.deleteIds.length > 0) {
    bulkDeleteIcon(id);
  } else {
    changeUploadIcon(filePath, id, fileName);
  }
};
</script>

<template>
  <IconUploadEmpty v-if="data.length === 0 && isActive" />
  <template v-if="data.length">
    <div class="flex justify-between">
      <div class="text-12 text-text-dark-300 flex h-[32px] items-center justify-between gap-4">Uploaded icons</div>
      <GButton v-if="isShowViewAll" type="link" size="semi-medium" @click="() => emit('active-uploaded')"
        >View all</GButton
      >
    </div>
    <div class="grid grid-cols-5 gap-8">
      <template v-for="icon in data" :key="icon.node.id">
        <IconPreview
          type="uploaded"
          :icon="icon"
          :selected-item="selectedItem"
          :delete-ids="deleteIds"
          :enable-select-delete="true"
          @bulk-select-icon="(id: string) => bulkDeleteIcon(id)"
          @on-select="onClickWrapperIcon">
        </IconPreview>
      </template>
    </div>
  </template>
</template>
