<script lang="ts" setup>
import type { ScreenType } from '@gem/common';
import { getFactors } from '@gem/common';
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    fallback?: LayoutValue;
    maxCol?: number;
    device?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'change', value: number): void;
}>();

const layouts = computed(() => {
  if (props.device === 'desktop')
    return Array.from({ length: props.maxCol }).map((_, index) => {
      return index + 1;
    });
  return getFactors(props.maxCol);
});

const handleChangeLayout = (cols: number) => {
  if (cols === props.value?.cols?.length) return;
  emit('change', cols);
};
</script>

<template>
  <div class="grid w-full grid-cols-3 gap-16">
    <div
      v-for="item in layouts"
      :key="item"
      class="group/box flex h-36 cursor-pointer items-center justify-center rounded-[6px] px-4"
      :class="{
        'bg-underlay-dark-blue': item === value?.cols?.length,
      }"
      @click="handleChangeLayout(item)">
      <div class="grid h-28 w-full gap-[2px]" :style="{ gridTemplateColumns: `repeat(${item}, 1fr)` }">
        <span
          v-for="(_, index) in item"
          :key="index"
          class="rounded-medium"
          :class="{
            'bg-primary-300': item === value?.cols?.length,
            'bg-dark-200 group-hover/box:bg-dark-150': item !== value?.cols?.length,
          }"></span>
      </div>
    </div>
  </div>
</template>
