<script setup lang="ts">
import type { LabelWithLang, SettingUIHelpType, LabelVariant, LangKey } from '@gem/element-setting-ui';
import Tip from './Tip.vue';
import type { ScreenType } from '@gem/common';
import { capitalizeSentences } from '@gem/common';
import DeviceSelectPopup from '../components/device-select/DeviceSelectPopup.vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import { useI18n } from '@gem/i18n';
import { computed } from 'vue';

const { locale } = useI18n({ useScope: 'global' });

type SettingTitleProps = {
  labelWithLang?: LabelWithLang;
  label?: string;
  variant?: LabelVariant;
  help?: SettingUIHelpType;
  showDevices?: boolean;
};

const forceHiddenDevice = false;

withDefaults(defineProps<SettingTitleProps>(), {
  variant: 'primary',
});

const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);

const handleChangeActiveDevice = (device: ScreenType) => {
  settingSideBarStore.setCurrentDevice(device);
};
</script>

<template>
  <div
    v-if="!!labelWithLang || !!label"
    class="text-12 flex h-[32px] items-center justify-between gap-4"
    :class="{
      'text-text-dark-500 font-medium': variant === 'primary',
      'text-light-200 font-semibold': variant === 'bold',
      'text-text-dark-300': variant === 'secondary',
    }">
    <div class="flex items-center gap-4">
      {{ capitalizeSentences(label ?? labelWithLang?.[locale as LangKey] ?? labelWithLang?.en ?? '') }}
      <DeviceSelectPopup
        v-if="!forceHiddenDevice && showDevices"
        :current-screen="currentDevice"
        @change-screen="handleChangeActiveDevice" />
      <div v-if="help" class="flex items-center">
        <Tip v-if="help" :content="help.content" :button="help.button" :media="help.media" />
      </div>
    </div>
  </div>
</template>
