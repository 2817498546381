import { computed } from 'vue';
import type { BackgroundImageProps, BackgroundImageValue } from '../types';
import { BG_POSITION_OPTIONS, FIXED_DEFAULT_IMAGE_DATA } from '../const';

export const useBackgroundImage = (props: BackgroundImageProps, emit: any) => {
  const defaultValue = computed(() => {
    return props.compoDefaultValue?.image?.src ? props.compoDefaultValue : FIXED_DEFAULT_IMAGE_DATA;
  });
  const state = props.controlChangeData?.state;
  const clearValue = computed(() => {
    return state
      ? undefined
      : {
          ...props.value,
          image: {
            src: '',
          },
        };
  });

  const positionValue = computed(
    () =>
      BG_POSITION_OPTIONS.find(
        (slot) => slot.value.x === props.value?.position?.x && slot.value.y === props.value?.position?.y,
      )?.slotName,
  );

  const handleChange = (value?: BackgroundImageValue) => {
    emit('controlChange', value);
  };
  const handleOnChange = (value?: BackgroundImageValue) => {
    emit('controlOnChange', value);
  };
  const handleClear = () => {
    handleChange(clearValue.value);
  };

  const handleChangeValue = (key: keyof BackgroundImageValue, value?: any) => {
    const newValue = {
      ...props.value,
      [key]: value,
    };
    handleChange(newValue);
  };
  const handleSetDefaultValue = () => {
    if (!props.value?.image?.src) handleChange(defaultValue.value);
  };

  const handleChangePosition = (value?: string) => {
    const positionVal = BG_POSITION_OPTIONS.find((slot) => slot.slotName === value)?.value;
    if (positionVal) handleChangeValue('position', positionVal);
  };

  return {
    positionValue,
    handleChange,
    handleOnChange,
    handleClear,
    handleChangeValue,
    handleSetDefaultValue,
    handleChangePosition,
  };
};
