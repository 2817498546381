<script lang="ts" setup>
import type { OptionSelect } from '@gem/uikit';
import { GInput } from '@gem/uikit-v2';
import { onClickOutside } from '@vueuse/core';
import { ref, watch } from 'vue';
import { isFloat, isStringNumber } from '../common';
import { debounce } from 'lodash';

const props = withDefaults(
  defineProps<{
    id: string;
    value: string;
    min?: number;
    isInteger?: boolean;
    max?: number;
    options?: OptionSelect[];
    class?: string;
  }>(),
  {
    min: 1,
    isInteger: true,
    options: () => [],
  },
);

const emit = defineEmits<{
  (event: 'onChange', name: string, value: string): void;
  (event: 'onFocus'): void;
  (event: 'onBlur'): void;
}>();

const inputRef = ref<InstanceType<typeof GInput> | null>(null);
const latestValue = ref<string>(props.value);
const isOpenDropdown = ref(false);

const onFocusInput = () => {
  isOpenDropdown.value = true;
  emit('onFocus');
};

const debouncedOnChange = debounce((value: string) => {
  emit('onChange', props.id, value);
}, 500);

const onBlurInput = () => {
  debouncedOnChange.cancel();
  if (inputRef.value?.updateValue && inputRef.value.value !== latestValue.value) {
    inputRef.value?.updateValue(latestValue.value);
    emit('onChange', props.id, Number(latestValue.value).toString());
  }
  emit('onBlur');
};

const isValidForLatestValueSetup = (value: string) => {
  const isNumber = isStringNumber(value);
  const isGreaterMax = props.max ? Number(value) > props.max : false;
  const isNotInteger = props.isInteger ? isFloat(Number(value)) : false;
  return !(Number(value) < props.min || !isNumber || isGreaterMax || isNotInteger);
};

const onChangeFromValue = (value: string) => {
  if (isValidForLatestValueSetup(value)) {
    latestValue.value = Number(value).toString();
  }
  debouncedOnChange(value);
};

const onSelect = (value: string) => {
  latestValue.value = value;
  inputRef.value?.setValue(latestValue.value);
  emit('onChange', props.id, value);
  isOpenDropdown.value = false;
};

//@ts-ignore
onClickOutside(inputRef, () => {
  isOpenDropdown.value = false;
});

watch(
  () => props.value,
  (value) => {
    if (isValidForLatestValueSetup(value)) {
      latestValue.value = value;
    }
  },
);
</script>
<template>
  <div class="relative">
    <GInput
      ref="inputRef"
      :value="value"
      input-style="normal"
      :classes="`${props.class} !text-center !text-text-dark-500`"
      @handle-blur="onBlurInput"
      @focus="onFocusInput"
      @on-change="onChangeFromValue" />
    <div
      v-if="isOpenDropdown && options.length > 0"
      class="bg-dark-400 absolute -left-40 top-40 w-[180px] rounded-xl p-8">
      <div
        v-for="option in options"
        :key="option.id"
        class="hover:bg-dark-250 flex h-36 cursor-pointer items-center gap-12 rounded-xl px-8"
        @click="() => onSelect(option.id)">
        <div class="flex flex-1 items-center gap-12">
          <GBaseIcon
            name="status-check"
            width="16"
            height="16"
            class="invisible"
            :class="{
              '!visible': option.id == props.value,
            }" />
          <p class="text-text-dark-500 text-12 leading-5">{{ option.name }}</p>
        </div>
        <p class="text-12 text-text-dark-300 text-right leading-5">{{ option.des }}</p>
      </div>
    </div>
  </div>
</template>
