import type { OptionSelect } from '@gem/uikit';
import dayjs from '@gem/uikit/src/helpers/dayjs';
import { useDebounceFn } from '@vueuse/core';
import { computed } from 'vue';
import { getLabel } from '../../common';
import { CreationDateType, CompareType } from '../../constant';
import type { CreationDateEmit, CreationDateProps } from '../types';

export const useCreationDate = (props: CreationDateProps, emit: CreationDateEmit) => {
  const DATE_OPTIONS: OptionSelect[] = [
    {
      id: '7',
      name: 'A week',
      des: '7 days',
    },
    {
      id: '30',
      name: 'A month',
      des: '30 days',
    },
  ];

  const CREATION_OPTIONS = [
    {
      id: CreationDateType.DURATION,
      name: getLabel(CreationDateType.DURATION),
      value: CreationDateType.DURATION,
    },
    {
      id: CreationDateType.EXACT_DATE,
      name: getLabel(CreationDateType.EXACT_DATE),
      value: CreationDateType.EXACT_DATE,
    },
    {
      id: CreationDateType.BETWEEN_DATES,
      name: getLabel(CreationDateType.BETWEEN_DATES),
      value: CreationDateType.BETWEEN_DATES,
    },
  ];

  const handleSelectDateType = (value: CreationDateType) => {
    emit('onUpdate', {
      ...props.data,
      type: value,
      conditionType: value === CreationDateType.EXACT_DATE ? CompareType.EXACT : CompareType.BETWEEN,
    });
  };

  const handleChangeDate = useDebounceFn((controlId: string, value: string | Date) => {
    emit('onUpdate', {
      ...props.data,
      [controlId]: value,
    });
  }, 300);

  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const message = computed(() => {
    const { type, from, to, days } = props.data;
    if (type === CreationDateType.DURATION && Number(days) < 1) return 'Amount number must bigger than 1';
    if (type !== CreationDateType.BETWEEN_DATES) return '';
    if (dayjs(from).isAfter(dayjs(to).add(-1, 'day'))) return 'The 1st number must be smaller than 2nd number';
    return '';
  });
  return {
    handleSelectDateType,
    handleChangeDate,
    message,
    CREATION_OPTIONS,
    DATE_OPTIONS,
    addDays,
  };
};
