<script setup lang="ts">
import { GInput } from '@gem/uikit-v2';
import { ref } from 'vue';
import { useInputValidateValue } from './hooks/useInputValidateValue';
import type { InputNumberProps } from './type';

const props = defineProps<InputNumberProps>();

const { modifyValueByMinMax } = useInputValidateValue(props);

const inputRef = ref<any>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string, controlId?: string): void;
  (e: 'controlOnChange', value?: string, controlId?: string): void;
  (e: 'focus', event: any): void;
}>();

const handelControlChange = (value: string) => {
  emit('controlChange', modifyValueByMinMax(value), props.id);
};

const handelControlOnChange = (value: string) => {
  inputRef.value?.updateValue(value);
  emit('controlOnChange', value, props.id);
};

const handleFocus = (e: any) => {
  emit('focus', e);
};
</script>

<template>
  <GInput
    ref="inputRef"
    v-bind="props"
    type="number"
    :value="modifyValueByMinMax(props.value)"
    @click-out-side="handelControlChange"
    @change="handelControlChange"
    @on-change="handelControlOnChange"
    @focus="handleFocus" />
</template>
