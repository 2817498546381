import { computed } from 'vue';
import { PositionLabel } from '../../position/constants';
import type { PositionType } from '../../position/types';
import type { BadgePositionControlTypes, BadgePositionEmits, PlacementAvailableValue } from '../types';
import type { IconName } from '@gem/icons';

export const useBadgePosition = (props: BadgePositionControlTypes, emits: BadgePositionEmits) => {
  const placement = computed(() => props.value?.placement);

  const change = (value: BadgePositionControlTypes['value']) => emits('change', value);

  const onChange = (newValue?: string) => {
    if (!props.value) return;
    change({
      ...props.value,
      placement: newValue,
    });
    emits('controlChange');
  };

  const onDeferChange = (key?: string, newValue?: string) => {
    const validatedValue = newValue;
    const numberValue = newValue?.replace('px', '');
    if (!Number.isInteger(Number(numberValue)) || Number(numberValue) < 0 || !key) {
      return;
    }

    change({
      ...props.value,
      [key]: validatedValue,
    });
  };

  const onBlur = (key: string, newValue?: string) => {
    if (newValue === props.value?.[key as keyof PositionType]) {
      return;
    }

    if (newValue?.length === 0) {
      return change({
        ...props.value,
        [key]: '0px',
      });
    }

    const numberValue = newValue?.replace('px', '');
    change({
      ...props.value,
      [key]:
        !Number.isInteger(Number(numberValue)) || Number(numberValue) < 0
          ? props.value?.[props.id as keyof PositionType]
          : newValue,
    });
  };

  const gap: Record<keyof Omit<PositionType, 'placement'>, PlacementAvailableValue> = {
    topGap: {
      label: 'Top gap',
      availablePosition: ['top', 'top-left', 'top-right'],
    },
    bottomGap: {
      label: 'Bottom gap',
      availablePosition: ['bottom', 'bottom-left', 'bottom-right'],
    },
    leftGap: {
      label: 'Left gap',
      availablePosition: ['left', 'top-left', 'bottom-left'],
    },
    rightGap: {
      label: 'Right gap',
      availablePosition: ['right', 'top-right', 'bottom-right'],
    },
  };

  const contentDisplay = computed(() => {
    const placement = props.value?.placement as keyof typeof PositionLabel;
    if (!placement) return '';
    return Object.keys(PositionLabel).includes(placement) ? PositionLabel[placement] : '';
  });

  const comboIcon = computed(() => `gp-position-${props.value?.placement}` as IconName);

  const checkAvailablePosition = (gapType: PlacementAvailableValue, placement?: string) => {
    if (!placement) return false;
    return gapType.availablePosition.includes(placement);
  };

  return {
    placement,
    onChange,
    onBlur,
    onDeferChange,
    gap,
    contentDisplay,
    comboIcon,
    checkAvailablePosition,
  };
};
