import { CONTROL_TYPE_WITH_POPOVER_NUMBER, useControlChange } from '@gem/element-setting-ui';
import type { ComboSettingProps, SettingUIControl } from '../types';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import type { IconName } from '@gem/icons';
import useControlData from '../../hooks/useControlData';
import { capitalizeFirstLetter, getColorFormGlobalStyle, TYPOGRAPHY_STYLES } from '@gem/common';
import { computed } from 'vue';
import { useComboLinkWithSetting } from './useComboLinkWithSetting';
import { useComboCheckExistSettingValue } from './useComboCheckExistSettingValue';

export const useComboSetting = (props: ComboSettingProps) => {
  const isDisabled = computed(() => props.data.disabled && !!props.data.options?.disableMessage);
  const settingSideBarStore = useSettingSideBarStore();
  const {
    linkWithSettingID,
    linkWithSettingValue,
    linkWithSettingIcon,
    hasLinkWithSetting,
    handleClearLinkWithSetting,
    handleAddDefaultLinkWithSetting,
  } = useComboLinkWithSetting(props);
  const { getContentDisplayByVerifySetting } = useComboCheckExistSettingValue(props);

  const enableCheckExistSettingValue = computed(() => props.data.enableCheckSettingsValue);
  const hasSettingID = computed(() => props.data.setting?.id);
  const fixedValue = computed(() => props.data.fixedValue);
  const isFullWidth = computed(() =>
    props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
  );

  const controlData = computed(() => props.data);
  const { controlProps } = useControlData({
    controlData,
  });

  const isEnable = computed(() => controlProps?.value?.value);

  const isCleanValue = computed(
    () =>
      linkWithSettingID.value &&
      (!linkWithSettingValue.value || linkWithSettingValue.value === props.data?.defaultValueWhenClear),
  );

  const comboDisplayValue = computed(() => {
    if (enableCheckExistSettingValue.value) {
      return getContentDisplayByVerifySetting();
    } else {
      if (hasSettingID.value) return isEnable.value ? fixedValue.value : '';
      if (props.data.comboType === 'color') {
        return getColorFormGlobalStyle(linkWithSettingValue.value, settingSideBarStore.globalStyles);
      }

      if (linkWithSettingValue.value) {
        if (props.data?.isTypoValue) {
          const selectedStyle = TYPOGRAPHY_STYLES.find((item) => item.id === linkWithSettingValue.value);
          if (selectedStyle) return selectedStyle.name;
        }
        if (props.data?.capitalizeDisplayValue) {
          return capitalizeFirstLetter(linkWithSettingValue.value?.toString().replace('-', ' '));
        }
      }

      if (isCleanValue.value) return '';
      return linkWithSettingValue.value ?? fixedValue.value;
    }
  });

  const comboIcon = computed(() => {
    const iconPrefix = props.data.iconPrefix;
    if (iconPrefix && linkWithSettingValue.value) return `${iconPrefix}-${linkWithSettingValue.value}` as IconName;
    return comboDisplayValue.value ? props.data.iconName : undefined;
  });

  const comboSvg = computed(() => {
    return !comboIcon.value && comboDisplayValue.value && linkWithSettingIcon.value;
  });

  const comboColor = computed(() => {
    return props.data.comboType === 'color' ? comboDisplayValue.value : undefined;
  });

  const comboImage = computed(() => {
    return props.data.comboType === 'image' ? comboDisplayValue.value : undefined;
  });

  const totalPopover = computed(() => {
    let total = 0;
    function loopControl(controls: SettingUIControl[]) {
      if (controls?.length) {
        total++;
        controls.forEach((item) => {
          if (item.type === 'combo') total++;
          if (CONTROL_TYPE_WITH_POPOVER_NUMBER[1].includes(item.mapTo?.control.type ?? '')) total += 1;
          if (CONTROL_TYPE_WITH_POPOVER_NUMBER[2].includes(item.mapTo?.control.type ?? '')) total += 2;
          loopControl(item.controls || []);
        });
      }
    }
    if (props.data.controls?.length) {
      loopControl(props.data.controls);
    }
    return total;
  });

  const controlChangeData = computed(() => controlProps.value.controlChangeData);
  const { change } = useControlChange(controlChangeData);

  const handleClear = () => {
    if (props.data.setting?.id) {
      change(false);
    } else {
      hasLinkWithSetting && handleClearLinkWithSetting();
    }
  };

  const handleEnableCombo = () => {
    if (props.data.setting?.id && !isEnable.value) {
      change(true);
    } else if (props.data.compoDefaultValue) {
      hasLinkWithSetting && isCleanValue.value && handleAddDefaultLinkWithSetting();
    }
  };

  return {
    linkWithSettingIcon,
    comboDisplayValue,
    totalPopover,
    comboIcon,
    comboSvg,
    comboColor,
    comboImage,
    isFullWidth,
    controlProps,
    isDisabled,
    handleEnableCombo,
    handleClear,
  };
};
