import { getCurrentValueFromControl, type Control } from '@gem/common';
import type { ComboSettingProps } from '../types';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import { computed } from 'vue';

export const useComboCheckExistSettingValue = (props: ComboSettingProps) => {
  const settingSideBarStore = useSettingSideBarStore();
  const currentDevice = computed(() => settingSideBarStore.currentDevice);
  const fixedValue = computed(() => props.data.fixedValue);
  const hasActiveSettings = (settings: Control['setting'][]) => {
    const mapControls = settingSideBarStore.mapControls;
    return settings.some((setting) => {
      const data = mapControls?.[setting.id];
      if (!data) return false;

      let controlValue = getCurrentValueFromControl({
        control: data,
        screenId: currentDevice.value,
      });

      if (setting.state) {
        controlValue = controlValue?.[setting.state];
      }

      return !!controlValue;
    });
  };
  const getContentDisplayByVerifySetting = () => {
    const controls = props.data.controls;
    const settings: Control['setting'][] = [];
    controls?.forEach((item) => {
      item.setting?.id && settings.push(item.setting);
      if (item.controls?.length) {
        item.controls.forEach((i) => {
          i.setting?.id && settings.push(i.setting);
        });
      }
    });
    if (hasActiveSettings(settings)) {
      return fixedValue.value;
    }
    return '';
  };

  return {
    getContentDisplayByVerifySetting,
  };
};
