<script setup lang="ts">
import type { GridSettingInput, RowLayoutProps } from '@gem/element-setting-ui';
import { ColumnWithSlider, PresetSettingLayout, SettingLayout } from '@gem/element-setting-ui';
import { checkSortedArray, type ScreenType } from '@gem/common';

import { computed } from 'vue';
import type { SvgName } from '@gem/icons';
import HeroBannerLayoutRecommend from './components/HeroBannerLayoutRecommend.vue';
import { getOrderItem } from '@gem/control/src/control/hero-banner-layout/helper';
import { useHeroBannerLayoutSettingsUpdate } from './hooks/useHeroBannerLayoutSettingsUpdate';

const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const emit = defineEmits<{
  (
    e: 'control-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-on-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-change-children-v2',
    contentSide: 'left' | 'right',
    options?: {
      noRecordHistory: boolean;
    },
  ): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
}>();

const { handleChangeLayout, handleUpdateCols, isShowColumnWithSlider } = useHeroBannerLayoutSettingsUpdate(props, emit);

function getBanner(type: string, bannerMap: Record<string, any>) {
  return bannerMap[type] || null;
}

const maxColumn = computed(() => props.devices?.desktop?.default?.cols?.length ?? 2);
const bannerMap = {
  left: { icon: 'gp-hero-banner-left-active', content: 'Middle left' },
  right: { icon: 'gp-hero-banner-right-active', content: 'Middle right' },
  center: { icon: 'gp-hero-banner-center-active', content: 'Center' },
  'left-top': { icon: 'gp-hero-banner-top-left-active', content: 'Top left' },
  'center-top': { icon: 'gp-hero-banner-center-top-active', content: 'Top center' },
  'right-top': { icon: 'gp-hero-banner-right-top-active', content: 'Top right' },
  'left-bottom': { icon: 'gp-hero-banner-bottom-left-active', content: 'Bottom left' },
  'center-bottom': { icon: 'gp-hero-banner-center-bottom-active', content: 'Bottom center' },
  'right-bottom': { icon: 'gp-hero-banner-right-bottom-active', content: 'Bottom right' },
};

const presetContent = computed((): { icon: SvgName; content: string } => {
  const cols = props.value?.cols?.length;
  const type = props.value?.type;

  if (type) {
    return getBanner(type, bannerMap);
  }
  if (cols === 1)
    return {
      icon: 'gp-hero-banner-center-active',
      content: 'Center',
    };

  if (props.value?.cols?.length === 2 && swapped.value == true) {
    return {
      icon: 'gp-hero-banner-right-active',
      content: 'Middle right',
    };
  } else {
    return {
      icon: 'gp-hero-banner-left-active',
      content: 'Middle left',
    };
  }
});

const swapped = computed(
  () =>
    !checkSortedArray(
      props.childrens?.map((item) => getOrderItem(item.styles?.order || {}, props.currentScreen)) || [],
    ),
);
const swapOrder = (contentSide: 'left' | 'right') => {
  if (props.value?.cols && props.value.cols.length < 2) {
    emit('control-change-children-v2', contentSide, { noRecordHistory: true });
    return;
  }
  emit('control-change-children-v2', contentSide);
};
</script>

<template>
  <PresetSettingLayout
    :popover-label="{ en: currentScreen === 'mobile' ? 'Mobile' : 'Layout' }"
    :show-label="false"
    :icon="presetContent.icon"
    :content-display="presetContent.content">
    <div class="flex flex-col gap-16">
      <HeroBannerLayoutRecommend
        :value="value"
        :swapped="swapped"
        :maxCol="maxColumn"
        :currentScreen="currentScreen"
        @swap-order="swapOrder"
        @change-layout="(value, type) => handleChangeLayout(value, currentScreen, type)" />
      <SettingLayout
        v-if="isShowColumnWithSlider"
        layout="vertical"
        label-variant="secondary"
        :label-with-lang="{ en: 'Ratio' }">
        <template #control>
          <ColumnWithSlider
            :disabled="value?.display !== 'fill'"
            :cols="value?.cols"
            @control-change="(value) => handleUpdateCols('change', value, currentScreen)"
            @control-on-change="(value) => handleUpdateCols('onChange', value, currentScreen)" />
        </template>
      </SettingLayout>
    </div>
  </PresetSettingLayout>
</template>
