export enum PositionLabel {
  'top-left' = 'Top left',
  'top' = 'Top middle',
  'top-right' = 'Top right',
  'left' = 'Middle left',
  'center' = 'Center',
  'right' = 'Middle right',
  'bottom-left' = 'Bottom left',
  'bottom' = 'Bottom middle',
  'bottom-right' = 'Bottom right',
}
