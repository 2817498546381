<script setup lang="ts">
import { computed } from 'vue';
import type { DisplayType } from '../types';

import type { OptionItem } from '@gem/element-setting-ui';
import { OptionValue } from '@gem/element-setting-ui';

type RowLayoutColumnWidthProps = {
  displayType?: DisplayType;
};

const props = defineProps<RowLayoutColumnWidthProps>();

const emit = defineEmits<(e: 'controlChange', displayType: DisplayType) => void>();
const displayValue = computed(() => {
  return props.displayType ?? 'fill';
});

const displayOptions: {
  label: string;
  value: DisplayType;
}[] = [
  {
    label: 'Change ratio',
    value: 'fill',
  },
  {
    label: 'Fit to content',
    value: 'fit',
  },
];

const handleChangeDisplay = (value: OptionItem['value']) => {
  if (displayValue.value === value) return;
  emit('controlChange', value as DisplayType);
};
</script>

<template>
  <OptionValue id="displayType" :value="displayValue" :options="displayOptions" @control-change="handleChangeDisplay" />
</template>
