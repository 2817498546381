<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import type { THEME_DARK } from '../../const';

import { THEME_LIGHT } from '../../const';
import { strictInject } from '@gem/element-setting-ui';
import { GRADIENT_PROVIDE_KEY } from '../../../../consts/provideKeys';

defineProps<{
  theme: typeof THEME_DARK | typeof THEME_LIGHT;
  degrees?: number;
}>();

const { updateAngle } = strictInject(GRADIENT_PROVIDE_KEY);

const circle = ref<HTMLDivElement>();
const cursorStyle = ref('cursor-grab');

const calculateAngleDegrees = (x: number, y: number) => {
  const degrees = Math.round((Math.atan2(y, x) * 180) / Math.PI) + 90;
  if (degrees < 0) {
    return 360 + degrees;
  }
  return degrees;
};

const onRadiantMove = (e: MouseEvent) => {
  const rect = circle.value?.getBoundingClientRect();
  if (rect) {
    const r = rect.width / 2;
    const center = {
      x: rect.x + r,
      y: rect.y + r,
    };
    const opposite = e.clientY - center.y;
    const adjacent = e.clientX - center.x;
    const angle = calculateAngleDegrees(adjacent, opposite);
    updateAngle(angle);
  }
};

onMounted(() => {
  circle.value?.addEventListener('mousedown', (e) => {
    e.preventDefault();
    onRadiantMove(e);
    cursorStyle.value = 'cursor-grabbing';
    document.addEventListener('mousemove', onRadiantMove);
  });
  document.addEventListener('mouseup', (e) => {
    e.preventDefault();
    cursorStyle.value = 'cursor-grab';
    document.removeEventListener('mousemove', onRadiantMove);
  });
});

onUnmounted(() => {
  cursorStyle.value = 'cursor-grab';
  document.removeEventListener('mousemove', onRadiantMove);
});
</script>

<template>
  <div :class="`relative flex items-center ${cursorStyle}`">
    <div
      ref="circle"
      class="bg-dark-400 relative aspect-square w-[60px] overflow-hidden rounded-full"
      :class="{
        'bg-light-400': theme === THEME_LIGHT,
      }">
      <div
        class="relative flex h-full w-full items-start justify-center rounded-full p-[1px]"
        :style="{ transform: `rotate(${degrees}deg)` }">
        <div
          class="bg-light-500 h-8 w-8 rounded-full"
          :class="{
            '!bg-dark-400': theme === THEME_LIGHT,
          }"></div>
      </div>
      <div
        class="bg-dark-500 !absolute top-1/2 left-1/2 h-[40px] w-[40px] -translate-x-1/2 -translate-y-1/2 rounded-full">
        <span
          class="text-14 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 leading-6"
          :class="{
            '!text-text-light-400': theme === THEME_LIGHT,
          }"
          >{{ degrees }}&deg;</span
        >
      </div>
    </div>
  </div>
</template>
