import { parseUnit } from '@gem/control';

type InputValidateProps = {
  max?: number;
  min?: number;
  defaultValue?: string;
};
export const useInputValidateValue = (props: InputValidateProps) => {
  const modifyValueByMinMax = (value?: string) => {
    if (!value) return value;
    const numberValue = parseFloat(value || '0');
    if (props.min !== undefined && numberValue < props.min) return props.min.toString();
    if (props.max !== undefined && numberValue > props.max) return props.max.toString();
    return numberValue.toString();
  };

  const isNoModifyValue = (value?: string) => {
    return value && ['custom', 'mix', 'default'].includes(value.toLowerCase());
  };

  const handleNegativeValue = (value?: string) => {
    const numberValue = parseInt(value?.toString() || '');
    if (numberValue >= 0) return value;
    else {
      const [, unit] = parseUnit(value || '');
      return `${numberValue * -1}${unit}`;
    }
  };

  return {
    modifyValueByMinMax,
    isNoModifyValue,
    handleNegativeValue,
  };
};
