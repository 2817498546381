import type { ComputedRef } from 'vue';
import { nextTick, ref } from 'vue';
import { debounce, isURL } from '@gem/common';
import { useDomainAutoComplete } from './useDomainAutoComplete';
import type { PickLinkProps } from '../types';

export const useSearchLink = (
  data: ComputedRef<{ linkValue: PickLinkProps['value']; isTypeOpenPage: boolean; list: any[] }>,
  emit: (event: string, payload?: any) => void,
  afterClearSearchBox?: () => void,
  onClickPageListItem?: (item: any) => void,
  sortSelectedPageList?: () => void,
) => {
  const searchVal = ref('');
  const isValidLink = ref(false);
  const refInputSearch = ref<any>();
  const suggestionLinks = ref<any[]>([]);
  const isHideDropdownPageType = ref(false);
  const isFocusInputSearch = ref(false);

  const onInputSearchBox = (value?: string) => {
    debounce(() => {
      if (!value) return onClearSearchBox();
      searchVal.value = value;

      if (!data.value.isTypeOpenPage) {
        if (isURL(value)) return;
        return onSearch(value);
      }

      const { isValid, completedDomains } = useDomainAutoComplete(searchVal.value);
      isValidLink.value = isValid;
      if (isValidLink.value || completedDomains?.length > 0) {
        suggestionLinks.value = completedDomains;
        isHideDropdownPageType.value = true;
      }

      onSearch(value);
      // isHideDropdownPageType.value = false;
    }, 100);
  };

  const onClearSearchBox = () => {
    searchVal.value = '';
    isValidLink.value = false;
    suggestionLinks.value = [];
    afterClearSearchBox?.();
    onSearch('');
  };

  const onSearch = (keyword: string) => {
    emit('control-search', keyword);
  };

  const onFocusSearchBox = () => {
    isFocusInputSearch.value = false;
    nextTick(() => {
      isFocusInputSearch.value = true;
    });
  };

  const onEnterSearchBox = (close: () => void) => {
    if (!data.value.isTypeOpenPage) return;

    validateSearchBox(close);
  };

  const validateSearchBox = (close?: () => void) => {
    const inputVal = searchVal.value;
    if (!inputVal || !inputVal.trim() || inputVal === data.value.linkValue?.link) return;

    const { isValid } = useDomainAutoComplete(inputVal);
    if (!data.value?.list?.length && isValid && suggestionLinks.value?.[0]) {
      onClickPageListItem?.(suggestionLinks.value[0]);
      close && close();
      searchVal.value = '';
      sortSelectedPageList?.();
    }
  };

  return {
    searchVal,
    isValidLink,
    refInputSearch,
    suggestionLinks,
    isFocusInputSearch,
    isHideDropdownPageType,
    onInputSearchBox,
    onFocusSearchBox,
    onEnterSearchBox,
    onClearSearchBox,
    validateSearchBox,
  };
};
