<script lang="ts" setup>
import type { DisplayTriggerBadgeProps, DisplayTriggerBadgeEmits } from './types';
import { withDefaults } from 'vue';
import GTooltip from '@gem/uikit-v2/src/base/GTooltip.vue';
import { GIcon } from '@gem/icons';
import GLoadingPlaceholder from '@gem/uikit-v2/src/base/GLoadingPlaceholder.vue';
import { TriggerType } from './constant';
import InputComboLayout from '../../sidebar-setting/layout/InputComboLayout.vue';
import SettingLayout from '../../sidebar-setting/layout/SettingLayout.vue';
import { settingLayoutLabel } from './constant';
import { triggerTypeLabel, settingLayoutIcon } from './constant';
import { useDisplayTriggerBadge } from './hooks/useDisplayTriggerBadge';

const props = withDefaults(defineProps<DisplayTriggerBadgeProps>(), {
  value: () => [],
});
const emit = defineEmits<DisplayTriggerBadgeEmits>();

const {
  displayTriggerComponent,
  triggerActive,
  displayTriggerItems,
  contentDisplay,
  onAddTrigger,
  onUpdateTrigger,
  onDeleteTrigger,
  onEdit,
  onLoadMore,
  triggerValue,
} = useDisplayTriggerBadge(props, emit);
</script>

<template>
  <div class="gemx-control">
    <div class="relative mb-12 flex h-[36px] items-center justify-between">
      <div class="!text-14 flex items-center gap-8 whitespace-nowrap font-medium text-white">
        Display conditions
        <GTooltip content-class="w-[252px] !whitespace-normal break-words" :is-teleport="true" placement="bottom">
          <GIcon name="gp-question-18" width="16" height="16" class="!text-text-dark-100" viewBox="0 0 18 18" />
          <template #content>
            Badge show on all products in Editor, but only on those meeting all conditions on the live page
          </template>
        </GTooltip>
      </div>
    </div>
    <div class="flex flex-col gap-16">
      <template v-if="isLoading">
        <GLoadingPlaceholder
          v-for="n in displayTriggerItems.length || 1"
          :key="n"
          cls="!rounded-xl"
          dark
          width="248px"
          height="72px" />
      </template>
      <template v-else>
        <template v-for="item in Object.values(TriggerType)" :key="item">
          <SettingLayout :label-with-lang="{ en: settingLayoutLabel[item] }" layout="horizontal">
            <template #control>
              <InputComboLayout
                :id="id"
                placeholder="Add..."
                :label="{ en: triggerTypeLabel[item] }"
                :content-display="contentDisplay[item].value"
                :show-label="false"
                :is-full-width="true"
                :combo-icon="triggerValue[item] && settingLayoutIcon[item]"
                :level="1"
                :combo-i-d="item"
                :combo-parent-i-d="''"
                :combo-root-parent-i-d="item"
                @click="() => onAddTrigger(item)"
                @clear="() => onDeleteTrigger(item)">
                <component
                  :is="displayTriggerComponent[item]"
                  :data="triggerValue[item]"
                  :active="triggerActive === item"
                  :product-tags="productTags"
                  :money-format="moneyFormat"
                  :is-loading="isLoading"
                  :shop-domain="shopDomain"
                  @on-edit="onEdit"
                  @on-load-more="onLoadMore"
                  @on-update="onUpdateTrigger"
                  @on-search="(value: string) => emit('onSearch', value)" />
              </InputComboLayout>
            </template>
          </SettingLayout>
        </template>
      </template>
    </div>
  </div>
</template>
