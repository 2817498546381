import type { ComponentOptionsMixin } from 'vue';
import { ref, computed } from 'vue';
import { TriggerType, conditionProductBadgeDefault } from '../constant';
import type { DisplayTriggerBadgeProps, DisplayTriggerBadgeEmits, DisplayTrigger, TriggerEvent } from '../types';
import { useGetSubtitle } from './useGetSubtitle';
import InventoryStatus from '../InventoryStatus/InventoryStatus.vue';
import DiscountPrice from '../DiscountPrice/DiscountPrice.vue';
import PriceRange from '../PriceRange/PriceRange.vue';
import ProductTag from '../ProductTag/ProductTag.vue';
import CreationDate from '../CreationDate/CreationDate.vue';

export const useDisplayTriggerBadge = (props: DisplayTriggerBadgeProps, emit: DisplayTriggerBadgeEmits) => {
  const displayTriggerComponent: Record<TriggerType, ComponentOptionsMixin> = {
    [TriggerType.INVENTORY_STATUS]: InventoryStatus,
    [TriggerType.DISCOUNT_PRICE]: DiscountPrice,
    [TriggerType.PRICE_RANGE]: PriceRange,
    [TriggerType.PRODUCT_TAGS]: ProductTag,
    [TriggerType.CREATION_DATE]: CreationDate,
  };

  const triggerActive = ref('');
  const displayTriggerItems = computed(() => {
    const arr = props.value;
    return arr?.sort((a, b) => Number(a?.priority) - Number(b?.priority)) || [];
  });

  const triggerValue = computed(() => {
    return Object.values(TriggerType).reduce((acc, type) => {
      acc[type] = props?.value?.find((item) => item?.triggerEvent === type);
      return acc;
    }, {} as Record<TriggerType, DisplayTrigger | undefined>);
  });

  const contentDisplay = useGetSubtitle(triggerValue, props.moneyFormat);

  const onAddTrigger = (triggerEvent: TriggerEvent) => {
    const trigger: DisplayTrigger = conditionProductBadgeDefault[triggerEvent];
    triggerActive.value = trigger?.triggerEvent ?? '';
    return handleControlChange([...displayTriggerItems.value, trigger]);
  };

  const onUpdateTrigger = (data: DisplayTrigger) => {
    return handleControlChange(
      displayTriggerItems.value.map((item) => (item?.triggerEvent === data?.triggerEvent ? data : item)),
    );
  };

  const onDeleteTrigger = (triggerEvent: TriggerEvent) => {
    return handleControlChange(displayTriggerItems.value.filter((item) => item?.triggerEvent !== triggerEvent));
  };

  const handleControlChange = (value: DisplayTrigger[]) => {
    return emit('controlChange', value);
  };

  const onEdit = () => {
    emit('onEdit');
  };

  const onLoadMore = () => {
    emit('onLoadMore');
  };
  return {
    displayTriggerComponent,
    triggerActive,
    displayTriggerItems,
    contentDisplay,
    onAddTrigger,
    onUpdateTrigger,
    onDeleteTrigger,
    onEdit,
    onLoadMore,
    triggerValue,
  };
};
