import type { GridSettingInput, RowLayoutProps } from '@gem/element-setting-ui';
import { computed } from 'vue';
import type { ScreenType } from '@gem/common';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';

export const useHeroBannerLayoutSettingsUpdate = (props: RowLayoutProps, emit?: any) => {
  const sideBarStore = useSettingSideBarStore();

  const isShowColumnWithSlider = computed(() => (props.value?.cols?.length || 0) > 0);

  const handleUpdateCols = (type: 'change' | 'onChange', cols: number[] | undefined, screenId: ScreenType) => {
    const newValue = { ...props.value, cols };
    emit(type === 'change' ? 'control-change' : 'control-on-change', props.id, newValue, screenId);
    changeActiveDevice(screenId);
  };

  const handleChangeLayout = (newCols: number, device: ScreenType, type?: string) => {
    if (!newCols) return;
    if (device === 'desktop') {
      const input: GridSettingInput[] = [
        {
          newValue: {
            ...props.value,
            cols: Array.from({ length: newCols }, () => 12 / newCols),
            type,
          },
          screenId: 'desktop',
        },
        {
          newValue: undefined,
          screenId: 'tablet',
        },
        {
          newValue: {
            cols: [12],
            display: 'fill',
          },
          screenId: 'mobile',
        },
      ];
      emit('control-change-layout', input);
    } else {
      emit(
        'control-change',
        props.id,
        {
          ...props.value,
          cols: Array.from({ length: newCols }, () => 12 / newCols),
          type,
        },
        device,
      );
    }
    changeActiveDevice(device);
  };

  const changeActiveDevice = (device: ScreenType) => {
    sideBarStore.setCurrentDevice(device);
  };

  return {
    isShowColumnWithSlider,
    handleUpdateCols,
    handleChangeLayout,
  };
};
