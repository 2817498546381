import { debounce } from '@gem/common';
import { DEFAULT_UNIT } from '@gem/control';
import type { ControlInputStepperTypes } from '@gem/element-setting-ui';
import { useDebounceFn } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { useInputValidateValue } from './useInputValidateValue';

export const useInputStepper = (props: ControlInputStepperTypes, emit: any) => {
  const { modifyValueByMinMax } = useInputValidateValue(props);
  const val = ref(props.value ? parseFloat(props.value as any) : '0');
  const previousInputValue = ref(props.value ? parseFloat(props.value as any) : '0');
  const inputRef = ref<any>();
  const isFocus = ref(false);
  const isInputChange = ref(false);
  const isHoverButton = ref(false);
  const stepValue = computed(() => props.step || 1);

  const handleOnChange = (value: string) => {
    isInputChange.value = true;
    val.value = value;
    handleEmit('controlOnChange');
  };

  const handleChange = () => {
    if (!isInputChange.value) return;
    handleEmit('controlChange');
  };

  const handleEmit = (type: 'controlChange' | 'controlOnChange') => {
    let newValue = val.value.toString().replace(',', '.');
    let parsedValue = parseFloat(newValue);
    if (!isNaN(parsedValue)) {
      parsedValue = props.disableDecimal ? Math.floor(parsedValue) : parsedValue;
      newValue = modifyValueByMinMax(parsedValue.toString()) as string;
    } else {
      newValue = previousInputValue.value.toString();
    }
    newValue = newValue || previousInputValue.value.toString();
    if (props.includeUnit && DEFAULT_UNIT.includes(props.includeUnit)) {
      newValue = `${newValue}${props.includeUnit}`;
    }
    if (type === 'controlChange') {
      const updateValue = parseFloat(newValue);
      val.value = updateValue;
      inputRef.value?.updateValue(updateValue);
      emit('controlChange', newValue);
      previousInputValue.value = parseFloat(newValue);
      isInputChange.value = false;
    } else emit('controlOnChange', newValue);
  };

  const handleUpDownValue = (e: Event, type: 'up' | 'down') => {
    e.preventDefault();
    if (isNaN(parseFloat(val.value.toString()))) {
      val.value = previousInputValue.value;
      return;
    }
    debounce(() => {
      const newValue =
        type === 'up'
          ? (Number(val.value) + stepValue.value).toFixed(2)
          : (Number(val.value) - stepValue.value).toFixed(2);
      val.value = parseFloat(modifyValueByMinMax(newValue) as string);
      handleEmit('controlOnChange');
      debouncedClickOnChange();
    }, 100);
  };

  const debouncedClickOnChange = useDebounceFn(() => {
    handleEmit('controlChange');
  }, 500);

  const onMouseOverButton = () => {
    isHoverButton.value = true;
  };

  const onMouseLeaveButton = () => {
    isHoverButton.value = false;
  };

  watch(
    () => props.value,
    (newValue) => {
      previousInputValue.value = parseFloat(newValue as any);
      if (newValue === undefined) return;

      val.value = parseFloat(newValue as any);
    },
  );

  return {
    val,
    inputRef,
    isFocus,
    isHoverButton,
    handleOnChange,
    handleChange,
    handleUpDownValue,
    onMouseOverButton,
    onMouseLeaveButton,
  };
};
