<script lang="ts" setup>
import { CompareType } from '../constant';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import InputBadge from '../common/InputBadge.vue';
import type { PriceRangeEmit, PriceRangeProps } from './types';
import { usePriceRange } from './hooks/usePriceRange';

const props = defineProps<PriceRangeProps>();
const emit = defineEmits<PriceRangeEmit>();
const { onSelect, min, message, onChangeQuantityValue } = usePriceRange(props, emit);
</script>

<template>
  <PopoverContent title="Amount">
    <CompareTypeSelect :quantity-type="data.conditionType" @on-change="onSelect" />
    <RangeInput
      v-if="data.conditionType === CompareType.BETWEEN"
      :quantity-type="data.conditionType"
      :from="data.from"
      :to="data.to"
      :is-integer="false"
      @on-update-value="onChangeQuantityValue" />
    <InputBadge v-else id="from" :value="data.from" :min="min" :is-integer="false" @on-change="onChangeQuantityValue" />
    <template #message>
      <div v-if="message" class="text-12 flex items-center gap-8 text-red-200">
        <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
        <p>{{ message }}</p>
      </div>
    </template>
  </PopoverContent>
</template>
