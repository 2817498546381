import type { FileSelectFragment } from '@gem/control/src/type/graphql';
import { FILE_NAME_MAX_CHARACTERS_ALLOWED } from '../constants';
import type { ImageUploadType } from '../types';

export const handleFormatFileName = (file: any): string => {
  const { fileName } = file;
  const filenameParts = fileName.split('.');
  const hasExtension = filenameParts.length > 1;

  if (!hasExtension) {
    return fileName.length > FILE_NAME_MAX_CHARACTERS_ALLOWED
      ? `${fileName.slice(0, FILE_NAME_MAX_CHARACTERS_ALLOWED)}...`
      : fileName;
  }

  const extension = filenameParts.pop()!;
  const name = filenameParts.join('.');

  if (fileName.length <= FILE_NAME_MAX_CHARACTERS_ALLOWED) {
    return fileName;
  }

  const reservedLength = extension.length + 4;
  const maxNameLength = FILE_NAME_MAX_CHARACTERS_ALLOWED - reservedLength;

  const firstPart = name.slice(0, maxNameLength);
  const lastPart = name.slice(-2);

  return `${firstPart}...${lastPart}.${extension}`;
};

export const convertImageFile = (item: FileSelectFragment) => {
  return {
    id: item?.node?.id ?? '',
    size: item?.node?.size,
    width: item?.node?.width,
    height: item?.node?.height,
    isShow: item?.node?.isShow,
    fileKey: item?.node?.fileKey ?? '',
    filePath: item?.node?.filePath ?? '',
    fileName: item?.node?.fileName ?? '',
    mimeType: item?.node?.mimeType ?? '',
    backupFileKey: item?.node?.backupFileKey ?? '',
  } as ImageUploadType;
};

export const getUploadCareImageUrlByMimeType = (imageData?: ImageUploadType) => {
  if (imageData?.mimeType == 'image/svg+xml' && imageData?.filePath && imageData?.filePath.includes('ucarecdn')) {
    return `${imageData.filePath}-/format/auto/`;
  }
  return imageData?.filePath || '';
};

export const getImageUrlPreview = (imageData?: ImageUploadType) => {
  const filePath = imageData?.filePath ?? '';
  if (filePath.includes('cdn.shopify.com') && imageData?.mimeType !== 'image/tiff') {
    return filePath.replace(/(\.[^.]+)$/, '_180x180$1');
  } else if (filePath.includes('ucarecdn')) {
    return `${getUploadCareImageUrlByMimeType(imageData)}-/preview/180x180/`;
  } else {
    return filePath;
  }
};
