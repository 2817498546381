import { onMounted, ref, watch } from 'vue';
import type { SliderType } from '../type';

export const useSlider = (props: SliderType, emit: any) => {
  const sliderValue = ref(props.modelValue);
  const sliderRef = ref<any>(null);
  const previousValue = ref(props.modelValue);

  const getProgress = (value: number, min: number, max: number) => {
    if (max && value && value > max && props.ignoreMax) return 100;
    return ((value - min) / (max - min)) * 100;
  };

  const setCSSProgress = (progress: number) => {
    sliderRef.value.style.setProperty('--ProgressPercent', `${progress}%`);
  };

  const onInputChange = (e: Event) => {
    const sliderValue = (e.target as HTMLInputElement).value;
    emit('control-on-change', sliderValue);
    changeProgress();
  };

  const handleBlur = () => {
    if (sliderValue.value === previousValue.value) return;

    previousValue.value = sliderValue.value;
    emit('control-change');
  };

  const changeProgress = () => {
    const progress = getProgress(sliderValue.value, sliderRef.value.min, sliderRef.value.max);
    const extraWidth = typeof props.extraWidth === 'number' ? props.extraWidth : (100 - progress) / 10;
    setCSSProgress(progress + extraWidth);
  };

  onMounted(() => {
    changeProgress();
  });

  watch(
    () => props.modelValue,
    (newVal, oldVal) => {
      previousValue.value = oldVal;
      sliderValue.value = newVal;
      if (props.max && newVal > props.max) {
        sliderValue.value = props.max;
      }
      if (props.min && newVal < props.min) {
        sliderValue.value = props.min;
      }

      sliderRef.value.min = props.min;
      sliderRef.value.max = props.max;

      changeProgress();
    },
  );

  return {
    sliderValue,
    sliderRef,
    onInputChange,
    handleBlur,
  };
};
