<script setup lang="ts">
import type { ComboSettingProps } from '@gem/element-setting-ui';
import { InputComboLayout } from '@gem/element-setting-ui';
import ControlSettings from './ControlSettings.vue';
import { useComboSetting } from './hooks/useComboSetting';
import { GTooltip } from '@gem/uikit-v2';

const props = defineProps<ComboSettingProps>();

const {
  comboDisplayValue,
  comboIcon,
  comboSvg,
  controlProps,
  isFullWidth,
  totalPopover,
  comboColor,
  comboImage,
  isDisabled,
  handleEnableCombo,
  handleClear,
} = useComboSetting(props);
</script>

<template>
  <div data-test="combo-setting" class="relative" :class="{ 'opacity-70': isDisabled }">
    <div v-if="isDisabled" class="rounded-12 absolute top-0 right-0 z-[100] h-full w-[140px] cursor-not-allowed">
      <GTooltip
        placement="top"
        class="relative left-0 !block h-full w-full"
        content-class="max-w-[200px] whitespace-normal"
        :is-teleport="true">
        <template #content>
          <span v-html="data.options?.disableMessage" />
        </template>
      </GTooltip>
    </div>
    <InputComboLayout
      :id="controlProps?.id"
      :label="data?.label"
      :is-full-width="isFullWidth"
      :help="data.help"
      :level="data.level || 1"
      :total-popover="totalPopover"
      :placeholder="data.placeholder"
      :popover-label="data.popoverLabel"
      :combo-icon="!isDisabled ? comboIcon : undefined"
      :combo-svg="comboSvg"
      :combo-color="comboColor"
      :combo-image="comboImage"
      :content-display="!isDisabled ? comboDisplayValue?.replace('#', '') : ''"
      :is-hide-clear="data?.isHideClear"
      :disable-clear-message="data?.disableClearMessage"
      :combo-i-d="data?.comboID || ''"
      :combo-parent-i-d="data?.comboParentID || ''"
      :combo-root-parent-i-d="data?.comboRootParentID || ''"
      data-test="InputComboLayout"
      @click="handleEnableCombo"
      @clear="handleClear">
      <ControlSettings v-if="data.controls?.length" :controls="data.controls" :total-popover="totalPopover" />
    </InputComboLayout>
  </div>
</template>
