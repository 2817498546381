import { computed } from 'vue';
import type { ComboSettingProps, SettingUIControl } from '../types';
import { getCurrentValueFromControl } from '@gem/common';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import useControlData from '../../hooks/useControlData';
import { useControlChange } from './useControlChange';

export const useComboLinkWithSetting = (props: ComboSettingProps) => {
  const settingSideBarStore = useSettingSideBarStore();
  const currentDevice = computed(() => settingSideBarStore.currentDevice);

  const linkWithSettingConfig = computed(() => {
    const fields = props.data.getValueFromSettingID?.split('.');
    return {
      settingID: fields?.[0],
      state: fields?.[1],
    };
  });
  const isShowSettingTitle = computed(() => props.data.isShowSettingTitle);
  const linkWithSettingID = computed(() => linkWithSettingConfig.value.settingID);
  const linkWithSettingState = computed(() => linkWithSettingConfig.value.state);
  const linkWithSettingValue = computed(() => getContentByControlID(linkWithSettingID.value)?.toString());
  const linkWithSettingIcon = computed(() => getContentByControlID(props.data.getIconFromSettingID));
  const linkWithSettingUI = computed(() => {
    const comboSettings = getAllComboSettingData();
    return comboSettings?.find((item) => item.setting?.id === linkWithSettingID.value);
  });

  const { controlProps } = useControlData({ controlData: linkWithSettingUI });
  const controlChangeData = computed(() => controlProps.value.controlChangeData);
  const { change } = useControlChange(controlChangeData);

  function getAllComboSettingData() {
    const settings: SettingUIControl[] = [];

    const loop = (controls: SettingUIControl[]) => {
      controls.forEach((item) => {
        item?.setting?.id && settings.push(item);
        item.controls?.length && loop(item.controls);
      });
    };
    loop(props.data.controls || []);
    return settings;
  }

  function getContentByControlID(id?: string) {
    const controls = props.data.controls?.map((item) => (item.type === 'group' ? item.controls : item)).flat();
    const control = controls?.find((item) => item?.mapTo?.control?.id === id)?.mapTo?.control;
    if (control) {
      const value = getCurrentValueFromControl({ control, screenId: currentDevice.value });
      let linkWithSettingValue = linkWithSettingState.value ? value?.[linkWithSettingState.value] : value;
      linkWithSettingValue =
        linkWithSettingValue?.toString().toLocaleLowerCase() === 'transparent' ? undefined : linkWithSettingValue;
      if (isShowSettingTitle.value) {
        const controlOptionSelected = control?.options && control.options.find((item: any) => item.value === value);
        const settingTitle = controlOptionSelected?.title || controlOptionSelected?.label;
        return settingTitle || linkWithSettingValue;
      }
      return linkWithSettingValue;
    }
  }

  const handleClearLinkWithSetting = () => {
    change(props.data.defaultValueWhenClear);
  };

  const handleAddDefaultLinkWithSetting = () => {
    change(props.data.compoDefaultValue);
  };

  return {
    linkWithSettingValue,
    linkWithSettingIcon,
    hasLinkWithSetting: !!linkWithSettingID.value,
    linkWithSettingID,
    handleClearLinkWithSetting,
    handleAddDefaultLinkWithSetting,
  };
};
