import type { BgAttachmentOptions, BgRepeatOptions, BgSizeOptions } from './types';

export const BG_POSITION_OPTIONS = [
  { slotName: 'top-left', value: { x: 0, y: 0 } },
  { slotName: 'top', value: { x: 50, y: 0 } },
  { slotName: 'top-right', value: { x: 100, y: 0 } },
  { slotName: 'left', value: { x: 0, y: 50 } },
  { slotName: 'center', value: { x: 50, y: 50 } },
  { slotName: 'right', value: { x: 100, y: 50 } },
  { slotName: 'bottom-left', value: { x: 0, y: 100 } },
  { slotName: 'bottom', value: { x: 50, y: 100 } },
  { slotName: 'bottom-right', value: { x: 100, y: 100 } },
];
export const FIXED_DEFAULT_IMAGE_DATA = {
  image: {
    src: 'https://ucarecdn.com/06075225-af9e-460c-8e3c-6be63c0b773a/-/format/auto/',
    width: 1200,
    height: 480,
  },
  size: 'cover',
  position: {
    x: 50,
    y: 50,
  },
  repeat: 'no-repeat',
  attachment: 'scroll',
  preload: false,
};

export const BG_SIZE_OPTIONS: BgSizeOptions = [
  { label: 'Cover', value: 'cover', type: 'image', svgName: 'gp-image-cover' },
  { label: 'Contain', value: 'contain', type: 'image', svgName: 'gp-image-contain' },
];

export const BG_REPEAT_OPTIONS: BgRepeatOptions = [
  {
    value: 'repeat',
    iconName: 'gp-bg-repeat',
  },
  {
    value: 'repeat-x',
    iconName: 'gp-bg-repeat-x',
  },
  {
    value: 'repeat-y',
    iconName: 'gp-bg-repeat-y',
  },
  {
    value: 'no-repeat',
    iconName: 'polaris-disabled',
  },
];

export const BG_ATTACHMENT_OPTIONS: BgAttachmentOptions = [
  { value: 'scroll', title: 'Scroll' },
  { value: 'fixed', title: 'Fixed' },
  { value: 'local', title: 'Local' },
];

export const BG_QUALITY = [{ value: 'finest', label: 'Finest' }];

export const BG_VIDEO_TYPE = [
  { value: 'html5', title: 'Video hosting' },
  { value: 'youtube', title: 'Youtube' },
];
