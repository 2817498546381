import { computed } from 'vue';
import type { BackgroundVideoProps, BackgroundVideoValue } from '../types';
import type { IconName } from '@gem/icons';

export const useBackgroundVideo = (props: BackgroundVideoProps, emit: any) => {
  const fixedValue = {
    srcYoutube: 'https://www.youtube.com/watch?v=cyzh48XRS4M',
    type: 'youtube',
    loop: false,
  };

  const defaultValue = computed(() => {
    return props.compoDefaultValue || fixedValue;
  });

  const videoUrlDisplay = computed(() => {
    return props.value?.type === 'html5' ? props.value?.srcHtml5 : props.value?.srcYoutube;
  });

  const comboIcon = computed((): IconName | undefined => {
    if (!videoUrlDisplay.value) return undefined;
    return props.value?.type === 'html5' ? 'polaris-play-circle' : 'polaris-logo-youtube';
  });

  const handleChange = (value?: BackgroundVideoValue) => {
    emit('controlChange', value);
  };
  const handleOnChange = (value?: BackgroundVideoValue) => {
    emit('controlOnChange', value);
  };
  const handleClear = () => {
    const clearData =
      props.value.type === 'html5'
        ? {
            ...props.value,
            srcHtml5: undefined,
          }
        : {
            ...props.value,
            srcYoutube: undefined,
          };
    emit('controlChange', clearData);
  };

  const handleChangeValue = (key: keyof BackgroundVideoValue, value?: any) => {
    const newValue = {
      ...props.value,
      [key]: value,
    };
    handleChange(newValue);
  };

  const handleSetDefaultValue = () => {
    if (!videoUrlDisplay.value) handleChange(defaultValue.value);
  };
  return {
    videoUrlDisplay,
    comboIcon,
    handleChange,
    handleOnChange,
    handleClear,
    handleChangeValue,
    handleSetDefaultValue,
  };
};
