<script setup lang="ts">
import { SettingLayout, PresetSettingLayout } from '@gem/element-setting-ui';
import type { LayoutSelectorType } from '@gem/element-setting-ui';
import LayoutItems from './components/LayoutItems.vue';
import { useLayoutSelector } from './hooks/useLayoutSelector';
const props = withDefaults(defineProps<LayoutSelectorType>(), {
  itemPerRow: 3,
});

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
}>();

const { handleChangeLayout, presetContent, options } = useLayoutSelector(props, emit);
</script>

<template>
  <PresetSettingLayout
    :popover-label="popoverLabel || { en: 'Layout' }"
    :show-label="false"
    :icon="presetContent.icon"
    :content-display="presetContent.content">
    <div class="flex flex-col gap-16">
      <template v-if="groups?.length">
        <template v-for="(group, index) in groups" :key="index">
          <SettingLayout :label-with-lang="{ en: group.label }" layout="vertical" label-variant="secondary">
            <template #control>
              <LayoutItems
                :item-per-row="itemPerRow"
                :enable-item-opacity="enableItemOpacity"
                :options="group.options"
                :value="value"
                @change-layout="handleChangeLayout" />
            </template>
          </SettingLayout>
        </template>
      </template>
      <template v-if="options?.length && !groups?.length">
        <LayoutItems
          :item-per-row="itemPerRow"
          :enable-item-opacity="enableItemOpacity"
          :options="options"
          :value="value"
          @change-layout="handleChangeLayout" />
      </template>
      <slot />
    </div>
  </PresetSettingLayout>
</template>
