import type { ItemInfoConfig } from './types';

export const DEFAULT_PROPS = {
  hasAddMore: true,
  expandItem: false,
  titleItem: 'New Item',
};

export const COMPONENT_CONFIGS: Record<string, Partial<ItemInfoConfig>> = {
  Accordion: {
    title: 'Accordion',
    label: 'Accordion Item',
  },
  Tabs: {
    tag: 'TabItem',
    label: 'Tab Item',
    title: 'Tab',
  },
  Carousel: {
    tag: 'CarouselItem',
    title: 'Item',
  },
  IconList: {
    title: 'Item',
  },
  IconListV2: {
    title: 'Your custom text goes here',
  },
  FormDropdown: {
    title: 'Option',
  },
  ProductProperties: {
    title: 'Option',
  },
  IconListHoz: {
    title: 'Icon',
  },
  PostPurchaseAdvancedList: {
    title: 'Item',
  },
  Marquee: {
    title: 'Item',
  },
};

export const SUPPORT_CLICK_COMPONENTS = ['Marquee'];
export const SUPPORT_HOVER_COMPONENTS = ['Marquee'];
export const SUPPORT_ICON_COMPONENTS = ['IconListV2', 'Accordion'];
export const SUPPORT_EDITOR_INLINE_COMPONENTS = ['IconListV2', 'Accordion', 'Tab'];
export const SUPPORT_CHILD_COMPONENTS = ['ProductProperties', 'FormDropdown', 'IconListV2'];

export const ADVANCE_ITEM_TITLE_PATTERN = /^Item \d+$/;
