<script setup lang="ts">
import { BG_ATTACHMENT_OPTIONS, BG_REPEAT_OPTIONS, BG_SIZE_OPTIONS } from './const';
import { useBackgroundImage } from './hooks/useBackgroundImage';
import type { BackgroundImageProps } from './types';
import {
  InputComboLayout,
  SettingLayout,
  SettingTitle,
  Toggle,
  InputText,
  Dropdown,
  OptionValue,
  PositionControl,
  TextareaWithAction,
  OptionIllustration,
} from '@gem/element-setting-ui';

const props = defineProps<BackgroundImageProps>();
const emit = defineEmits<{
  (e: 'controlChange', value?: BackgroundImageProps['value']): void;
  (e: 'controlOnChange', value?: BackgroundImageProps['value']): void;
}>();

const { positionValue, handleChangeValue, handleClear, handleSetDefaultValue, handleChangePosition } =
  useBackgroundImage(props, emit);
</script>

<template>
  <div>
    <InputComboLayout
      placeholder="Add..."
      :label="{ en: 'Background image' }"
      :show-label="false"
      :combo-image="value?.image?.src"
      :content-display="value?.image?.src"
      :is-full-width="true"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      :is-hide-clear="isHideClear"
      @click="handleSetDefaultValue"
      @clear="handleClear">
      <div class="flex flex-col gap-12">
        <div class="border-dark-300 flex flex-col gap-16 border-b pb-20">
          <SettingLayout layout="vertical" :label-with-lang="{ en: 'Source' }" label-variant="primary">
            <template #control>
              <slot></slot>
            </template>
          </SettingLayout>

          <SettingLayout :label-with-lang="{ en: 'Scale' }" :label-variant="'secondary'">
            <template #control>
              <OptionIllustration
                id="bgSize"
                :value="value?.size || 'cover'"
                :options="BG_SIZE_OPTIONS"
                :enable-tooltip="true"
                @control-change="(value: any) => handleChangeValue('size', value)" />
            </template>
          </SettingLayout>

          <SettingLayout :label-with-lang="{ en: 'Position' }" :label-variant="'secondary'" align="top">
            <template #control>
              <PositionControl
                id="bgPosition"
                control-class="place-self-end"
                size-class="!w-full !aspect-[4/3]"
                :value="positionValue"
                @control-change="handleChangePosition" />
            </template>
          </SettingLayout>

          <SettingLayout :label-with-lang="{ en: 'Repeat' }" :label-variant="'secondary'">
            <template #control>
              <OptionValue
                id="bgRepeat"
                :value="value?.repeat || 'no-repeat'"
                :options="BG_REPEAT_OPTIONS"
                :icon-custom="true"
                @control-change="(value: any) => handleChangeValue('repeat', value)" />
            </template>
          </SettingLayout>
          <SettingLayout :label-with-lang="{ en: 'Attachment' }" :label-variant="'secondary'">
            <template #control>
              <Dropdown
                id="bgAttachment"
                :value="value?.attachment || 'scroll'"
                :options="BG_ATTACHMENT_OPTIONS"
                @control-change="(value) => handleChangeValue('attachment', value)" />
            </template>
          </SettingLayout>
        </div>
        <div v-if="!isBackground" class="border-dark-300 flex flex-col gap-12 border-b pb-20">
          <SettingTitle :label-with-lang="{ en: 'SEO' }" variant="primary" />
          <div class="flex flex-col gap-16">
            <SettingLayout :label-with-lang="{ en: 'Alt text' }" align="top">
              <template #control>
                <TextareaWithAction
                  id="alt-text"
                  :value="value?.altText"
                  :auto-height="true"
                  :default-rows="3"
                  placeholder="E.g: Classic white crewneck t-shirt with minimalist design"
                  @control-change="(value) => handleChangeValue('altText', value)" />
              </template>
            </SettingLayout>
            <SettingLayout :label-with-lang="{ en: 'Image title' }">
              <template #control>
                <InputText
                  id="bgImageTitle"
                  :value="value?.imageTitle"
                  placeholder="E.g: White t-shirt"
                  @control-change="(value) => handleChangeValue('imageTitle', value)" />
              </template>
            </SettingLayout>
          </div>
        </div>
        <SettingLayout
          v-if="!isBackground"
          layout="vertical"
          :label-with-lang="{ en: 'Optimize LCP' }"
          label-variant="primary">
          <template #control>
            <slot name="optimize-lcp"></slot>
            <SettingLayout :label-with-lang="{ en: 'Preload' }">
              <template #control>
                <Toggle
                  :value="value?.preload || false"
                  @control-change="(value?: boolean) => handleChangeValue('preload', value)"
              /></template>
            </SettingLayout>
          </template>
        </SettingLayout>
      </div>
    </InputComboLayout>
  </div>
</template>
