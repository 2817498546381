<script lang="ts" setup>
import type { ActionSettingInput } from '@gem/element-setting-ui';
import { useRowLayoutSettingsUpdate } from './hooks/useRowLayoutSettingsUpdate';
import type { GridSettingInput, RowLayoutProps } from './types';
import type { ScreenType } from '@gem/common';
import DeviceSettings from './components/DeviceSettings.vue';

const emit = defineEmits<{
  (
    e: 'control-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-on-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (e: 'control-change-children', input: ActionSettingInput[], device: ScreenType): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const {
  handleChangeLayout,
  handleReOrder,
  handleUpdateCols,
  handleChangeDisplay,
  handleChangeGap,
  handleRemoveItem,
  handleCopyItem,
  handleReorderColForPostPurchase,
} = useRowLayoutSettingsUpdate(props, emit);
</script>

<template>
  <div class="w-full" data-text="row-layout-control">
    <DeviceSettings
      v-bind="props"
      :current-screen="currentScreen"
      @copy-item="handleCopyItem"
      @remove-item="handleRemoveItem"
      @change-layout="handleChangeLayout"
      @change-cols="handleUpdateCols"
      @change-display="handleChangeDisplay"
      @change-gap="handleChangeGap"
      @re-order="handleReOrder"
      @re-order-post-purchase="handleReorderColForPostPurchase" />
  </div>
</template>
