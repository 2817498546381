<script lang="ts" setup>
import type { ScreenType } from '@gem/common';
import { GSvg } from '@gem/icons';
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
  type?: string;
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    swapped?: boolean;
    maxCol?: number;
    currentScreen?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'changeLayout', value: number, type?: string): void;
  (e: 'swap-order', contentSide: 'left' | 'right'): void;
}>();

const swapOrder = (contentSide: 'left' | 'right') => {
  emit('swap-order', contentSide);
};

const hasDisableLayout1ColMobile = computed(() => {
  return props.maxCol <= 1 && props.currentScreen !== 'desktop';
});

const layouts = computed(() => {
  const type = props?.value?.type;
  return [
    {
      active: type ? type === 'left-top' : false,
      icon: 'gp-hero-banner-left-top',
      name: 'Top left',
      col: 2,
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'left-top');
        swapOrder('left');
      },
    },
    {
      active: type ? type === 'center-top' : false,
      col: 1,
      icon: 'gp-hero-banner-top-center',
      name: 'Top center',
      disabled: false,
      onClick: () => {
        handleChangeLayout(1, 'center-top');
      },
    },
    {
      active: type ? type === 'right-top' : false,
      icon: 'gp-hero-banner-right-top',
      col: 2,
      name: 'Top right',
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'right-top');
        swapOrder('right');
      },
    },
    {
      active: type ? type === 'left' : props.value?.cols?.length === 2 && !props.swapped,
      icon: 'gp-hero-banner-left',
      name: 'Middle left',
      col: 2,
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'left');
        swapOrder('left');
      },
    },
    {
      active: type ? type === 'center' : props.value?.cols?.length === 1,
      col: 1,
      icon: 'gp-hero-banner-center',
      name: 'Center',
      disabled: false,
      onClick: () => {
        handleChangeLayout(1, 'center');
      },
    },
    {
      active: type ? type === 'right' : props.value?.cols?.length === 2 && props.swapped,
      icon: 'gp-hero-banner-right',
      col: 2,
      name: 'Middle right',
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'right');
        swapOrder('right');
      },
    },
    {
      active: type ? type === 'left-bottom' : false,
      icon: 'gp-hero-banner-left-bottom',
      name: 'Bottom left',
      col: 2,
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'left-bottom');
        swapOrder('left');
      },
    },
    {
      active: type ? type === 'center-bottom' : false,
      col: 1,
      icon: 'gp-hero-banner-bottom-center',
      name: 'Bottom center',
      disabled: false,
      onClick: () => {
        handleChangeLayout(1, 'center-bottom');
      },
    },
    {
      active: type ? type === 'right-bottom' : false,
      icon: 'gp-hero-banner-right-bottom',
      col: 2,
      name: 'Bottom right',
      disabled: hasDisableLayout1ColMobile.value,
      onClick: () => {
        if (hasDisableLayout1ColMobile.value) return;
        handleChangeLayout(2, 'right-bottom');
        swapOrder('right');
      },
    },
  ];
});

const handleChangeLayout = (cols: number, type?: string) => {
  emit('changeLayout', cols, type);
};
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-3 gap-8">
    <div
      v-for="item in layouts"
      :key="item.name"
      class="group/layout grid max-w-full cursor-pointer gap-[2px] rounded-xl border border-transparent [&_svg_.opacity-path]:opacity-[0.3]"
      :class="{
        'border-primary-300 [&_svg_rect]:fill-light-200 [&_svg_.opacity-path]:opacity-0': item.active,
        '[&_svg_rect]:!fill-light-200 [&_svg_.opacity-path]:!opacity-[0.8]': item.disabled,
      }"
      @click="item?.onClick()">
      <GTooltip placement="top" strategy="fixed" display-type="block">
        <div
          class="bg-dark-400 group-hover/layout:[&_svg_rect]:fill-light-200 flex w-fit items-center justify-center overflow-hidden rounded-xl group-hover/layout:[&_svg_.opacity-path]:opacity-0 [&>span]:inline-flex">
          <GSvg :name="item.icon" />
        </div>
        <template #content>
          {{ item.name }}
        </template>
      </GTooltip>
    </div>
  </div>
</template>
