<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import DesktopIcon from './icons/DesktopIcon.vue';
import TabletIcon from './icons/TabletIcon.vue';
import MobileIcon from './icons/MobileIcon.vue';

const listDevices = {
  desktop: DesktopIcon,
  tablet: TabletIcon,
  mobile: MobileIcon,
};

type ScreenType = keyof typeof listDevices;

type Props = {
  controlType?: string;
  currentScreen?: ScreenType;
  menuClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'changeScreen', screenId: ScreenType): void;
}>();

// Methods
const controlHasDevices = computed(() => {
  const typeInvisible = ['visibility'];
  return !typeInvisible.includes(props.controlType || '');
});
const devices: ScreenType[] = ['desktop', 'tablet', 'mobile'];

const setActiveDevice = (screenId: ScreenType) => {
  emit('changeScreen', screenId);
};

const isMenuItemBottom = ref(false);
const menuButton = ref<HTMLElement | null>(null);

const updateMenuPosition = () => {
  if (!menuButton.value) return;

  const rect = menuButton.value.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const spaceBelow = viewportHeight - rect.bottom;
  const menuHeight = 150;
  isMenuItemBottom.value = spaceBelow < menuHeight;
};
</script>

<template>
  <div class="flex items-center">
    <Menu v-if="controlHasDevices" :class="menuClass" as="div" class="hover:rounded-medium relative flex items-center">
      <div ref="menuButton" @click="updateMenuPosition">
        <MenuButton
          class="menu-button text-text-dark-300 hover:rounded-medium hover:bg-dark-250 inline-flex h-24 w-24 items-center justify-center bg-opacity-20 font-medium transition duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
          <component :is="listDevices[currentScreen]" class="text-text-dark-100" width="20" height="20"></component>
        </MenuButton>
      </div>
      <MenuItems
        class="bg-dark-400 shadow-4dp rounded-12 absolute right-[-12px] z-[100] flex origin-top-right flex-col gap-4 p-4 focus:outline-none"
        :class="[isMenuItemBottom ? 'bottom-32' : 'top-24']">
        <MenuItem v-for="device in devices" :key="device">
          <GButtonV2
            type="ghost"
            size="semi-medium"
            :only-icon="device"
            icon-size="20"
            :active="device == currentScreen"
            @click="setActiveDevice(device)"></GButtonV2>
        </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>
