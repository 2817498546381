<script setup lang="ts">
import type { BadgePositionControlTypes, BadgePositionEmits } from './types';
import SettingLayout from '../../sidebar-setting/layout/SettingLayout.vue';
import InputComboLayout from '../../sidebar-setting/layout/InputComboLayout.vue';
import PositionControl from '../position/PositionControl.vue';
import InputAndSlider from '../inputs/InputAndSlider.vue';
import { useBadgePosition } from './hooks/useBadgePosition';

const props = defineProps<BadgePositionControlTypes>();

const emits = defineEmits<BadgePositionEmits>();

const { placement, onChange, onBlur, onDeferChange, gap, contentDisplay, comboIcon, checkAvailablePosition } =
  useBadgePosition(props, emits);
</script>

<template>
  <SettingLayout :label-with-lang="{ en: 'Choose' }" layout="horizontal">
    <template #control>
      <InputComboLayout
        :id="id"
        placeholder="Add..."
        :label="{ en: 'Position' }"
        :content-display="contentDisplay"
        :show-label="false"
        :is-full-width="true"
        :combo-icon="comboIcon"
        :level="1"
        :is-hide-clear="true"
        :combo-i-d="'badge-id'"
        :combo-parent-i-d="'badge-parent-i-d'"
        :combo-root-parent-i-d="'badge-root-parent-i-d'">
        <SettingLayout label="Choose" label-variant="secondary">
          <template #control>
            <PositionControl
              id="placement"
              :value="placement"
              :ignore-value="ignoreValue"
              class="mb-16 flex items-start"
              @control-change="onChange">
            </PositionControl>
          </template>
        </SettingLayout>

        <template v-for="(gapType, key) in gap" :key="key">
          <div v-if="checkAvailablePosition(gapType, placement)" class="">
            <SettingLayout :label="gapType.label" label-variant="secondary">
              <template #control>
                <div class="w-[140px] shrink-0">
                  <InputAndSlider
                    :id="key"
                    :value="parseInt(value?.[key] || '0')"
                    :control-change="onBlur"
                    @control-on-change="(value) => onDeferChange(key, value)" />
                </div>
              </template>
            </SettingLayout>
          </div>
        </template>
      </InputComboLayout>
    </template>
  </SettingLayout>
</template>
