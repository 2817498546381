import { computed, nextTick, ref } from 'vue';
import { useItemManagementContext } from './context/useItemManagementContext';
import type { ItemProps } from '../types';
import { scrollItemIntoView } from '../helpers';

export const useItemActions = (props: ItemProps) => {
  const showAction = ref(false);

  const { actions } = useItemManagementContext();

  const isDisabledDelete = computed(() => {
    return actions.handleCheckDisableAction('delete');
  });

  const isEditable = computed(() => {
    return actions.handleCheckIsEditable();
  });

  const handleDuplicateItem = () => {
    if (!props.index) return;

    actions.handleDuplicate(props.index);
    const newIndex = (parseInt(props.index) + 1).toString();
    nextTick(() => scrollItemIntoView(newIndex));
  };

  const handleShowDeleteActions = () => {
    showAction.value = true;
  };

  const handleCancelDeleteItem = () => {
    showAction.value = false;
  };

  const handleConfirmDeleteItem = () => {
    showAction.value = false;
    if (props.index) actions.handleDelete(props.index);
  };

  return {
    showAction,
    isEditable,
    isDisabledDelete,
    handleDuplicateItem,
    handleCancelDeleteItem,
    handleConfirmDeleteItem,
    handleShowDeleteActions,
  };
};
