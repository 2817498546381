<script setup lang="ts">
import { InputText, OptionValue } from '@gem/element-setting-ui';
import type { CustomContentProps } from './types';
import { computed } from 'vue';
import SettingLayout from '../../sidebar-setting/layout/SettingLayout.vue';

const props = defineProps<CustomContentProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: CustomContentProps['value']): void;
  (e: 'controlOnChange', value?: CustomContentProps['value']): void;
}>();

const prefix = computed(() => props.value?.prefix?.replaceAll('&nbsp;', ' '));
const suffix = computed(() => props.value?.suffix?.replaceAll('&nbsp;', ' '));

const handleOnChange = (id: string, value?: any) => {
  emit('controlOnChange', { ...props.value, [id]: value });
};

const handleChange = (id: string, value?: any) => {
  emit('controlChange', { ...props.value, [id]: value });
};

const options = [
  {
    label: '%',
    value: 'percentage',
    tooltip: 'Show discount by percentage',
  },
  {
    label: 'Net Discount',
    value: 'price',
    tooltip: 'Show discount by net reduction',
  },
];
</script>
<template>
  <div class="flex flex-col gap-16">
    <SettingLayout :label-with-lang="{ en: `Type` }">
      <template #control>
        <OptionValue
          id="unit"
          :options="options"
          :value="value?.unit"
          @control-change="(value) => handleChange('unit', value)" />
      </template>
    </SettingLayout>
    <SettingLayout :label-with-lang="{ en: `Content` }">
      <template #control>
        <div class="max-w-input-horizontal ml-auto flex w-full items-center gap-[6px]">
          <InputText
            id="prefix"
            :value="prefix"
            placeholder="SALE"
            @control-change="(value) => handleChange('prefix', value)"
            @control-on-change="(value) => handleOnChange('prefix', value)" />
          <span class="text-text-dark-100 text-12 font-regular">
            {{ value?.unit === 'percentage' ? '%' : '$' }}
          </span>
          <InputText
            id="suffix"
            :value="suffix"
            placeholder="OFF"
            @control-change="(value) => handleChange('suffix', value)"
            @control-on-change="(value) => handleOnChange('suffix', value)" />
        </div>
      </template>
    </SettingLayout>
  </div>
</template>
