<script lang="ts" setup>
import { SettingLayout, Dropdown } from '@gem/element-setting-ui';
import { computed } from 'vue';

type Props = {
  value?: string;
  popupList: any[];
};
const props = defineProps<Props>();
const val = computed(() => props.value);

const emit = defineEmits<{
  (e: 'change-popup', value: string): void;
  (e: 'create-popup'): void;
}>();
</script>

<template>
  <div class="flex items-center justify-between gap-16" data-test="editor-control-page-link">
    <SettingLayout :label-with-lang="{ en: 'Popup' }">
      <template #control>
        <Dropdown
          id="link"
          :options="popupList"
          empty-message="You don’t have any popup"
          :value="val || ''"
          disable-default
          blank-text="Choose one"
          :more-action="
            popupList?.length
              ? undefined
              : {
                  action: () => emit('create-popup'),
                  label: 'Create a popup',
                }
          "
          @control-change="(value) => emit('change-popup', value)" />
      </template>
    </SettingLayout>
  </div>
</template>
