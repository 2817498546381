import { computed } from 'vue';
import type { PositionControlTypes } from '../types';
import { PositionLabel } from '../constants';

export const usePositionSetting = (props: PositionControlTypes, emit: any) => {
  const slots = Object.keys(PositionLabel);
  const val = computed(() => props.value);
  const hideIndex = props.ignoreValue?.map((v) => slots.indexOf(v));

  const handleChange = (value?: string) => {
    emit('controlChange', value);
  };
  return { val, slots, hideIndex, handleChange };
};
