import { useInfiniteScroll } from '@vueuse/core';
import type { ComputedRef } from 'vue';
import { ref, nextTick } from 'vue';

export const useInfiniteScrollHandler = (apiLoading: ComputedRef<boolean | undefined>, emit: any) => {
  const infinityScroll = ref<any>(null);

  const infinityScrollToTop = () => {
    nextTick(() => {
      if (infinityScroll.value) {
        infinityScroll.value.$el.scrollTop = 0;
      }
    });
  };

  useInfiniteScroll(
    infinityScroll,
    () => {
      showMore();
    },
    { distance: 0 },
  );

  const showMore = () => {
    if (apiLoading.value) return;
    emit('show-more');
  };

  return { infinityScroll, infinityScrollToTop };
};
